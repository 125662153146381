/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCode,
  faFileCode,
  faCogs,
  faPlus,
  faCheck,
  faRefresh,
  faTimes,
  faToolbox,
  faCube,
  faTrash,
  faEdit,
  faEye,
  faCog,
  faIcons,
  faPager,
  faFileCircleCheck,
  faScrewdriverWrench
} from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  Modal,
  Button,
  Offcanvas,
  Dropdown,
  ListGroup,
  Badge,
  FloatingLabel,
  Form,
  Row,
  Col,
  InputGroup,
  Carousel,
  Image,
  Toast
} from 'react-bootstrap';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { urlEcommerceBackend, urlEcommerceFrontend } from '../recursos/configuracion';
import { obtenerPermiso, reportar } from '../recursos/funcion';
import { useUsuarioContexto } from '../proveedor/usuario';
import { Cargador } from '../componentes/Cargador';
import ScrollCategorias from '../componentes/editor/ScrollCategorias';
import { conseguirFormato } from '../recursos/funcion';
import { faUpload } from '@fortawesome/free-solid-svg-icons'; //ver
//import { subirImagen } from './ruta/a/tu/archivo';  // Ver la ruta para subir la imagen 



export default function Editor() {
  const cuenta = useUsuarioContexto();

  const [vital, setVital] = useState(false);

  const navegar = useNavigate();
  const parametros = useParams();
  const [codigopagina, setCodigoPagina] = useState(0);

  const [datos, setDatos] = useState([]);
  const [elegido, setElegido] = useState({
    nombre: '',
    estado: -1
  });
  const [estructura, setEstructura] = useState([]);

  const [contenidos, setContenidos] = useState({
    portadas: [],
    banners: [],
    sellos: [],
    contadores: [],
    promociones: [],
    productos: [],
    videos: []
  });

  const [promociones, setPromociones] = useState([]);

  const [marcas, setMarcas] = useState([]);

  const [categorias, setCategorias] = useState([]);

  const [posicionS, setPosicionS] = useState(0);

  const [posicionE, setPosicionE] = useState(0);

  const [seleccionPlantilla, setSeleccionPlantilla] = useState(-1);

  const [tipo, setTipo] = useState('');

  const [origenContenido, setOrigenContenido] = useState([]);

  const [tipoContenido, setTipoContenido] = useState([]);

  const [valorContenido, setValorContenido] = useState([]);

  const [cantidadContenido, setCantidadContenido] = useState([]);

  const [accion, setAccion] = useState(1);

  const [actividad, setActividad] = useState(true);

  const [izquierdo, setIzquierdo] = useState(false);

  const cerrarIzquierdo = () => setIzquierdo(false);
  const mostrarIzquierdo = () => setIzquierdo(true);

  const [inferior, setInferior] = useState(false);

  const cerrarInferior = () => setInferior(false);
  const mostrarInferior = () => setInferior(true);

  const [derecho, setDerecho] = useState(false);

  const cerrarDerecho = () => setDerecho(false);
  const mostrarDerecho = () => setDerecho(true);

  const [modalPagina, setModalPagina] = useState(false);

  const cerrarModalPagina = () => setModalPagina(false);
  const mostrarModalPagina = () => setModalPagina(true);

  const [modalNE, setModalNE] = useState(false);

  const cerrarModalNE = () => setModalNE(false);
  const mostrarModalNE = () => setModalNE(true);

  const [modalConfig, setModalConfig] = useState(false);

  const cerrarModalConfig = () => setModalConfig(false);

  const mostrarModalConfig = () => setModalConfig(true);

  const [carga, setCarga] = useState(false);

  const [carruselId, setCarruselId] = useState('');

  const [tamano, setTamano] = useState('');

  const [color, setColor] = useState('#fcfcfc');

  const [fechaInicio, setFechaInicio] = useState('');

  const [horaInicio, setHoraInicio] = useState('');

  const [fechaFin, setFechaFin] = useState('');

  const [horaFin, setHoraFin] = useState('');

  const [fondo, setFondo] = useState(null);

  const [dimensionesFondo, setDimensionesFondo] = useState({ width: 0, height: 0 });

  const [promocionId, setPromocionId] = useState('');

  const [cantidadProductos, setCantidadProductos] = useState(1);

  const [tamanoCarrusel, setTamanoCarrusel] = useState('');

  const [productos, setProductos] = useState([]);

  const [estadoSubir, setEstadoSubir] = useState(false); //ver

  const [imagen, setImagen] = useState(null); //ver


  //const [contadorId, setContadorId] = useState('');

  //const [duracion, setDuracion] = useState('');

  //const [inicio, setInicio] = useState('');

  const [elementos, setElementos] = useState([]);

  const [nuevoEstado, setNuevoEstado] = useState({ estado: 1 });

  const [carruselConfiguracion, setCarruselConfiguracion] = useState(null);

  const [relojConfiguracion, setRelojConfiguracion] = useState(null);

  const [esCategoria, setEsCategoria] = useState(false); // Estado para controlar el checkbox

  const [categoriaId, setCategoriaId] = useState('');

  // const handleConfiguracionCarrusel = (informacion) => {


  //   if (!informacion || informacion.length === 0) {
  //     console.error('La información no está disponible o está vacía');
  //     return;
  //   } else {
  //     console.log('La informacion no esta vacia');
  //   }

  //   // Verificar si la posición seleccionada es válida antes de acceder a 'elementos'
  //   if (posicionS < 0 || posicionS >= informacion.length || !informacion[posicionS]) {
  //     console.error('La posición seleccionada no es válida o no existe en la información.');
  //     return;
  //   } else {
  //     console.log(`La posición ${posicionS} es válida y existe en la información.`);
  //   }

  //   // Verificar si la estructura en la posición seleccionada contiene 'elementos'
  //   if (!informacion[posicionS].elementos) {
  //     console.error(`La estructura en la posición ${posicionS} no contiene "elementos".`);
  //   }

  //   if (!informacion[posicionS].elementos || !Array.isArray(informacion[posicionS].elementos)) {
  //     console.warn(`'elementos' no está definido como un array en la posición ${posicionS}. Inicializando 'elementos'.`);
  //     informacion[posicionS].elementos = [];
  //   } else {
  //     console.log(`'elementos' está definido correctamente en la posición ${posicionS}.`);
  //   }


  //   console.log('Configurando carrusel con:', {
  //     promocionId,
  //     cantidadProductos,
  //     tamanoCarrusel,
  //   });

  //   if (!promocionId) {
  //     //alert('Por favor, selecciona una promoción.');
  //     Swal.fire('Editor', 'Por favor, selecciona una promoción.', 'error');
  //     return;
  //   }

  //   if (!tamanoCarrusel) {
  //     //alert('Por favor, selecciona un tamaño para el carrusel.');
  //     Swal.fire('Editor', 'Por favor, selecciona un tamaño para el carrusel.', 'error');
  //     return;
  //   }

  //   // Obtener la promoción seleccionada por su ruta
  //   const promocionSeleccionada = promociones.find(promocion => promocion.ruta === promocionId);

  //   if (!promocionSeleccionada) {
  //     //alert('Promoción no encontrada.');
  //     Swal.fire('Editor', 'Promoción no encontrada.', 'error');
  //     return;
  //   }

  //   // Configurar la estructura del carrusel con las configuraciones seleccionadas
  //   const nuevoElemento = {
  //     id: informacion[posicionS].elementos.length,
  //     nombre: promocionSeleccionada.nombre,
  //     ruta: promocionSeleccionada.ruta,
  //     tipo: 'Carrusel Promociones',
  //     tamano: tamanoCarrusel,
  //     descripcion: `Carrusel de promociones con ${cantidadProductos} productos`,
  //     configuracion: {
  //       elemento: {
  //         titulo: {
  //           tamano: 20,
  //           color: "#000000",
  //         },
  //         fondo: {
  //           color: "#f8f9fa",
  //         },
  //         borde: {
  //           tamano: 1,
  //           redondeo: 5,
  //           color: "#f8f9fa",
  //         },
  //         efecto: {
  //           comportamiento: {
  //             animacion: "",
  //             repeticion: "",
  //             duracion: "",
  //             retraso: "",
  //           },
  //           entrada: {
  //             animacion: "",
  //             repeticion: "",
  //             duracion: "",
  //             retraso: "",
  //           },
  //           salida: {
  //             animacion: "",
  //             repeticion: "",
  //             duracion: "",
  //             retraso: "",
  //           },
  //         },
  //       },
  //       contenido: {
  //         fondo: {
  //           color: "#f8f9fa",
  //         },
  //         borde: {
  //           tamano: 1,
  //           redondeo: 5,
  //           color: "#c3c7cb",
  //         },
  //         texto: {
  //           tamano: 2,
  //           color: "#ec650a",
  //         },
  //         icono: {
  //           tamano: 25,
  //           color: "#f8f9fa",
  //         },
  //         sello: {
  //           tamano: 12,
  //         },
  //         efecto: {
  //           comportamiento: {
  //             animacion: "",
  //             repeticion: "",
  //             duracion: "",
  //             retraso: "",
  //           },
  //           entrada: {
  //             animacion: "",
  //             repeticion: "",
  //             duracion: "",
  //             retraso: "",
  //           },
  //           salida: {
  //             animacion: "",
  //             repeticion: "",
  //             duracion: "",
  //             retraso: "",
  //           },
  //         },
  //       },
  //     },
  //     descriptivo: {
  //       nombre: true,
  //       contado: false,
  //       cuota: false,
  //     },
  //     filtro: {
  //       origen: 'producto',
  //       tipo: 'promocion',
  //       valor: promocionSeleccionada.ruta,
  //       dato: '',
  //       cantidad: cantidadProductos,
  //     },
  //     contenidos: [], // Los productos se llenarán dinámicamente en función de la promoción
  //   };
  //   console.log('nuevoElemento');
  //   console.log(nuevoElemento);

  //   // Guardar la configuración en un estado global o contexto
  //   setCarruselConfiguracion(nuevoElemento);
  //   console.log('Fin de la función handleConfiguracionCarrusel');

  // };
  const handleConfiguracionCarrusel = (informacion) => {
    if (!informacion || informacion.length === 0) {
      console.error('La información no está disponible o está vacía');
      return;
    } else {
      console.log('La información no está vacía');
    }

    if (posicionS < 0 || posicionS >= informacion.length || !informacion[posicionS]) {
      console.error('La posición seleccionada no es válida o no existe en la información.');
      return;
    } else {
      console.log(`La posición ${posicionS} es válida y existe en la información.`);
    }

    if (!informacion[posicionS].elementos || !Array.isArray(informacion[posicionS].elementos)) {
      console.warn(`'elementos' no está definido como un array en la posición ${posicionS}. Inicializando 'elementos'.`);
      informacion[posicionS].elementos = [];
    } else {
      console.log(`'elementos' está definido correctamente en la posición ${posicionS}.`);
    }

    // Validación para asegurarse de que haya una promoción o categoría seleccionada
    if (!promocionId && !esCategoria) {
      Swal.fire('Editor', 'Por favor, selecciona una promoción o marca la opción de categoría.', 'error');
      return;
    }

    // Si es una categoría, valida que se haya seleccionado una
    if (esCategoria && !categoriaId) {
      Swal.fire('Editor', 'Por favor, selecciona una categoría.', 'error');
      return;
    }

    if (!tamanoCarrusel) {
      Swal.fire('Editor', 'Por favor, selecciona un tamaño para el carrusel.', 'error');
      return;
    }

    let nuevoElemento; // Declaración de la variable fuera de los bloques condicionales

    if (promocionId) {
      // Lógica existente para promociones
      const promocionSeleccionada = promociones.find(promocion => promocion.ruta === promocionId);

      if (!promocionSeleccionada) {
        Swal.fire('Editor', 'Promoción no encontrada.', 'error');
        return;
      }

      nuevoElemento = {
        id: informacion[posicionS].elementos.length,
        nombre: promocionSeleccionada.nombre,
        ruta: promocionSeleccionada.ruta,
        tipo: 'Carrusel Promociones',
        tamano: tamanoCarrusel,
        descripcion: `Carrusel de promociones con ${cantidadProductos} productos`,
        configuracion: { /* configuración */ },
        descriptivo: {
          nombre: true,
          contado: false,
          cuota: false,
        },
        filtro: {
          origen: 'producto',
          tipo: 'promocion',
          valor: promocionSeleccionada.ruta,
          dato: '',
          cantidad: cantidadProductos,
        },
        contenidos: [],
      };
    } else if (esCategoria && categoriaId) {
      // Lógica para categorías
      const categoriaSeleccionada = categorias.find(categoria => categoria.ruta === categoriaId);
      if (!categoriaSeleccionada) {
        Swal.fire('Editor', 'Categoría no encontrada.', 'error');
        return;
      }
      nuevoElemento = {
        id: informacion[posicionS].elementos.length,
        nombre: categoriaSeleccionada.nombre,
        ruta: categoriaSeleccionada.ruta,
        tipo: 'Carrusel Categorías',
        tamano: tamanoCarrusel,
        descripcion: `Carrusel de categorias con ${cantidadProductos} productos`,
        configuracion: { /* configuración */ },
        descriptivo: {
          nombre: true,
          contado: false,
          cuota: false,
        },
        filtro: {
          origen: 'producto',
          tipo: 'categoria',
          valor: categoriaSeleccionada.ruta,
          cantidad: cantidadProductos,
        },
        contenidos: [],
      };
    }

    console.log('nuevoElemento:', nuevoElemento);

    setCarruselConfiguracion(nuevoElemento);
    Swal.fire({
      icon: 'success',
      title: 'Configuración exitosa',
      text: 'El carrusel se ha configurado correctamente.',
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
      position: 'center',
    });
    console.log('Fin de la función handleConfiguracionCarrusel');
  };


  const tamanoMap = {
    pequeno: 4,  // Asumiendo que 'pequeño' ocupa 4 unidades
    mediano: 8,  // Asumiendo que 'mediano' ocupa 8 unidades
    grande: 12   // Asumiendo que 'grande' ocupa 12 unidades
  };

  const handleConfiguracionReloj = (informacion) => {
    console.log('handleConfiguracionReloj se ha llamado');
    console.log('Contenido de informacion:', informacion);
    console.log('Valor de posicionS:', posicionS);

    if (!informacion || informacion.length === 0) {
      console.error('La información no está disponible o está vacía');
      return;
    }

    if (posicionS < 0 || posicionS >= informacion.length || !informacion[posicionS]) {
      console.error('La posición seleccionada no es válida o no existe en la información.');
      return;
    }

    if (!informacion[posicionS].elementos) {
      console.error(`La estructura en la posición ${posicionS} no contiene "elementos".`);
      return;
    }

    const nuevoElemento = {
      id: informacion[posicionS].elementos.length,
      nombre: `Reloj Contador ${informacion[posicionS].nombre}`,
      tipo: 'RelojContador',
      fechaInicio,
      horaInicio,
      fechaFin,
      horaFin,
      tamano: tamano || 12, // Establece 12 como tamaño predeterminado si tamano es undefined
      color,
      fondo: fondo || null, // Aquí se añade la imagen de fondo si está disponible
      descripcion: `Reloj configurado en la posición ${posicionS}`,
    };

    console.log('nuevoElemento:', nuevoElemento);
    console.log('Estructura luego de configurar el reloj : ', estructura);


    setRelojConfiguracion(nuevoElemento);
    Swal.fire({
      icon: 'success',
      title: 'Configuración exitosa',
      text: 'El reloj se ha configurado correctamente.',
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
      position: 'center',
    });
    console.log('Fin de la función handleConfiguracionReloj');
  };



  async function recuperar(event) {
    try {
      const file = event.target.files[0]; // Obtenemos el archivo de imagen del input
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file); // Leemos el archivo como Data URL
        reader.onloadend = () => {
          const imagenDataUrl = reader.result;
          setImagen(imagenDataUrl); // Establecemos la imagen en el estado

          console.log("Imagen cargada correctamente:", imagenDataUrl);
        };
      } else {
        console.error("No se seleccionó ningún archivo.");
      }
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'promociones.jsx',
        'recuperarImagenUsuario',
        `${error.toString()}`,
        { error },
        1
      );
    }
  }

  // const handleConfiguracionReloj = (informacion) => {
  //   console.log('handleConfiguracionReloj se ha llamado');
  //   console.log('Contenido de información:', informacion);
  //   console.log('Valor de posicionS:', posicionS);

  //   if (!informacion || informacion.length === 0) {
  //     console.error('La información no está disponible o está vacía');
  //     return;
  //   }

  //   if (posicionS < 0 || posicionS >= informacion.length || !informacion[posicionS]) {
  //     console.error('La posición seleccionada no es válida o no existe en la información.');
  //     return;
  //   }

  //   if (!informacion[posicionS].elementos) {
  //     console.error(`La estructura en la posición ${posicionS} no contiene "elementos".`);
  //     return;
  //   }

  //   // Obtener el tamaño mapeado
  //   const tamanoNumerico = tamanoMap[tamano];

  //   // Validar que el tamaño seleccionado es correcto
  //   if (tamanoNumerico === undefined) {
  //     console.error('Tamaño no válido seleccionado.');
  //     Swal.fire('Error', 'Por favor, seleccione un tamaño válido.', 'error');
  //     return;
  //   }

  //   // Calcular el tamaño total actual en la sección
  //   const tamañoActual = calcularTamañoActual(informacion[posicionS]);

  //   // Validar que el tamaño total no exceda el límite
  //   if (tamañoActual + tamanoNumerico > 12) {
  //     console.error('El tamaño total excede el límite de 12 para la sección');
  //     Swal.fire('Error', 'El tamaño total excede el límite permitido para la sección.', 'error');
  //     return;
  //   }

  //   const nuevoElemento = {
  //     id: informacion[posicionS].elementos.length,
  //     nombre: `Reloj Contador ${informacion[posicionS].nombre || 'sin nombre'}`,
  //     tipo: 'RelojContador',
  //     configuracion: {
  //       fechaInicio,
  //       horaInicio,
  //       fechaFin,
  //       horaFin,
  //       tamano, // Solo almacenar el tamaño como cadena
  //       color,
  //       fondo: fondo ? fondo : null,
  //     },
  //     descripcion: `Reloj configurado en la posición ${posicionS}`,
  //   };

  //   console.log('nuevoElemento:', nuevoElemento);

  //   // Agregar el nuevo elemento a la configuración
  //   setRelojConfiguracion(nuevoElemento);
  //   Swal.fire({
  //     icon: 'success',
  //     title: 'Configuración exitosa',
  //     text: 'El reloj se ha configurado correctamente.',
  //     timer: 2000,
  //     timerProgressBar: true,
  //     showConfirmButton: false,
  //     position: 'center',
  //   });
  //   console.log('Fin de la función handleConfiguracionReloj');
  // };

  // const calcularTamañoActual = (seccion) => {
  //   let tamañoTotal = 0;
  //   seccion.elementos.forEach(elemento => {
  //     console.log('elemento.tamano');
  //     console.log(elemento.tamano);

  //     const tamañoElemento = tamanoMap[elemento.tamano];
  //     tamañoTotal += tamañoElemento;
  //   });
  //   console.log('Tamaño total acumulado en la sección:', tamañoTotal);
  //   return tamañoTotal;
  // };

  // async function recuperar() {
  //   setCarga(true);

  //   try {
  //     let respuesta = await Axios.get(
  //       `${urlEcommerceBackend}/promociones?pagina=${pagina}&intervalo=${intervalo}&estado=1`,
  //       {
  //         headers: {
  //           authorization: `Bearer ${cuenta.token}`
  //         }
  //       }
  //     );

  //     setDatos(respuesta.data.arrayJson);
  //     setCarga(false);

  //     // Obtener promociones activas
  //     let promociones_activas = await Axios.get(
  //       `${urlEcommerceBackend}/promociones/listarActivas`,
  //       {
  //         headers: {
  //           authorization: `Bearer ${cuenta.token}`
  //         }
  //       }
  //     );

  //     if (promociones_activas.data && promociones_activas.data.objetoJson) {
  //       setPromociones(promociones_activas.data.objetoJson);
  //     }

  //     // Obtener combos
  //     let combos = await Axios.get(
  //       `${urlEcommerceBackend}/productoCombo`,
  //       {
  //         headers: {
  //           authorization: `Bearer ${cuenta.token}`
  //         }
  //       }
  //     );

  //     if (combos.data && combos.data.objetoJson) {
  //       setCombos(combos.data.objetoJson);
  //     }

  //     // Obtener categorías
  //     let categorias = await Axios.get(
  //       `${urlEcommerceBackend}/categorias`,
  //       {
  //         headers: {
  //           authorization: `Bearer ${cuenta.token}`
  //         }
  //       }
  //     );

  //     setCategorias(categorias.data.arrayJson);

  //     // Obtener información del combo específico
  //     let combo = await Axios.get(
  //       `${urlEcommerceBackend}/productoCombo/${parametro.id}`,
  //       {
  //         headers: {
  //           authorization: `Bearer ${cuenta.token}`
  //         }
  //       });

  //     const comboData = combo.data.objetoJson[0];
  //     setEstado(comboData.estado);
  //     setCombo(comboData);
  //     setNombre(comboData.nombre);
  //     setDescripcion(comboData.descripcion);

  //     // Configurar promoción seleccionada si existe
  //     if (!(comboData.promos[0] === '' || comboData.promos[0] == undefined)) {
  //       setPromocionSeleccionada(comboData.promos[0]);

  //       const promocionEncontrada = promociones_activas.data.objetoJson.find(
  //         promocion => promocion.ruta === comboData.promos[0]
  //       );

  //       setPromocionSeleccionadaId(promocionEncontrada?.codigo || '');
  //     }

  //     const categoriaPreseleccionada = comboData.categorias[0]?.ruta || '';
  //     setCategoriaSeleccionada(categoriaPreseleccionada);
  //     setValorVenta(comboData.precio);
  //     setImagen(`https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/store/${comboData.imagenes[0].url["600"]}`);

  //   } catch (error) {
  //     await reportar(
  //       `${cuenta.nombre} ${cuenta.apellido}`,
  //       'Ecommerce Admin',
  //       'Frontend',
  //       'promociones.jsx',
  //       'recuperar',
  //       `${error.toString()}`,
  //       {
  //         error
  //       },
  //       1
  //     );

  //     setCarga(false);
  //   }
  // }


  // const handleFondoChange = async (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onloadend = async () => {
  //       setFondo(reader.result);

  //       // Crear una nueva imagen para obtener sus dimensiones
  //       const img = new Image();
  //       img.src = reader.result;
  //       img.onload = () => {
  //         setDimensionesFondo({ width: img.width, height: img.height });
  //       };

  //       // Establecer otros datos relacionados con la imagen subida
  //       setEstadoSubir(false);
  //       const datos = {
  //         formato: conseguirFormato(file.name),
  //         file: conseguirBase64(reader.result)
  //       };
  //       setDatos(datos);

  //       console.log('datos');
  //       console.log(datos);

  //       try {
  //         setEstadoSubir(true);

  //         const respuesta = await Axios.post(`${urlEcommerceBackend}/almacen/subirImagenReloj`, datos, {
  //           headers: {
  //             "Content-Type": "application/json",
  //             authorization: `Bearer ${cuenta.token}`
  //           }
  //         });

  //         // Verifica si la respuesta es válida antes de continuar
  //         if (!respuesta || !respuesta.data) {
  //           throw new Error('La respuesta del servidor no es válida');
  //         }

  //         // Resetear el input de archivo
  //         const inputFile = document.getElementById('contadorFondo');
  //         if (inputFile) {
  //           inputFile.value = '';
  //         }

  //         setFondo(reader.result);

  //         // Mostrar notificación de éxito
  //         const Toast = Swal.mixin({
  //           toast: true,
  //           position: 'bottom-end',
  //           showConfirmButton: false,
  //           timer: 3000,
  //           timerProgressBar: true,
  //           didOpen: (toast) => {
  //             toast.addEventListener('mouseenter', Swal.stopTimer);
  //             toast.addEventListener('mouseleave', Swal.resumeTimer);
  //           }
  //         });

  //         Toast.fire({
  //           icon: 'success',
  //           title: respuesta.data.descripcion || 'Imagen subida con éxito'
  //         });

  //       } catch (error) {
  //         console.error('Error al subir la imagen:', error);

  //         // Mostrar notificación de error
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Error al subir la imagen',
  //           text: error.message || 'Ocurrió un error inesperado'
  //         });

  //         // Reportar el error
  //         await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "subir", `${error.toString()}`, {
  //           error
  //         }, 1);
  //       } finally {
  //         setEstadoSubir(false);
  //       }

  //     };
  //   } else {
  //     Swal.fire(
  //       'Subida de imágenes',
  //       'No se ha seleccionado imagen',
  //       'info'
  //     );
  //   }
  // };

  const handleFondoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log('Archivo seleccionado:', file);

      // Obtener el nombre del archivo y el formato
      const nombreArchivo = file.name;
      const formatoArchivo = conseguirFormato(nombreArchivo);

      console.log('Nombre del archivo:', nombreArchivo);
      console.log('Formato del archivo:', formatoArchivo);
      const reader = new FileReader();

      reader.onloadend = () => {
        if (!reader.result) {
          console.error('reader.result no está disponible o es inválido.');
          return;
        }

        // Crear una nueva imagen para validar las dimensiones
        const img = document.createElement('img');
        img.src = reader.result;

        img.onload = () => {
          console.log('Imagen cargada con éxito:', img.width, img.height);

          // Validar las dimensiones máximas permitidas
          if (img.width <= 360 && img.height <= 510) {
            // Si la imagen cumple con las dimensiones, la mostramos como vista previa
            setFondo(reader.result);
            setDimensionesFondo({ width: img.width, height: img.height });
            console.log('Dimensiones de la imagen aceptadas.');

            // Aquí, después de validar y mostrar la vista previa, puedes subir la imagen
            // Convertir la imagen a base64 y pasarla a la función de subida
            subirImagen(reader.result, nombreArchivo, formatoArchivo);

          } else {
            console.error('La imagen excede las dimensiones permitidas Maximo(360x510).');
            Swal.fire({
              icon: 'error',
              text: 'Las dimensiones de la imagen debe tener las dimensiones esperadas. Maximo(360x510).'
            });
          }
        };

        img.onerror = (error) => {
          console.error('Error al cargar la imagen:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error al cargar la imagen',
            text: 'Hubo un problema al cargar la imagen. Intenta con otro archivo.'
          });
        };
      };

      reader.onerror = (error) => {
        console.error('Error en FileReader:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error al leer el archivo',
          text: 'Hubo un problema al leer el archivo. Intenta de nuevo.'
        });
      };

      reader.readAsDataURL(file);
    } else {
      Swal.fire(
        'Subida de imágenes',
        'No se ha seleccionado imagen',
        'info'
      );
    }
  };


  const [tiempoRestante, setTiempoRestante] = useState({ dias: 0, horas: 0, minutos: 0, segundos: 0 });

  const calcularTiempoRestante = () => {
    if (!fechaFin || !horaFin) return;

    const fechaHoraFin = new Date(`${fechaFin}T${horaFin}`);
    const ahora = new Date();
    const diferencia = fechaHoraFin - ahora;

    if (diferencia > 0) {
      const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
      const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
      const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

      setTiempoRestante({ dias, horas, minutos, segundos });
    } else {
      setTiempoRestante({ dias: 0, horas: 0, minutos: 0, segundos: 0 });
    }
  };

  const [configDefecto] = useState({
    elemento: {
      titulo: {
        tamano: 20,
        color: '#000000'
      },
      fondo: {
        color: '#f8f9fa'
      },
      borde: {
        tamano: 1,
        redondeo: 5,
        color: '#c3c7cb'
      },
      efecto: {
        comportamiento: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        },
        entrada: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        },
        salida: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        }
      }
    },
    contenido: {
      fondo: {
        color: '#f8f9fa'
      },
      borde: {
        tamano: 1,
        redondeo: 5,
        color: '#c3c7cb'
      },
      texto: {
        tamano: 2,
        color: '#EC650A'
      },
      icono: {
        tamano: 25,
        color: '#f8f9fa'
      },
      sello: {
        tamano: 12
      },
      efecto: {
        comportamiento: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        },
        entrada: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        },
        salida: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        }
      }
    }
  });

  const [configuracion, setConfiguracion] = useState(configDefecto);

  const [listaAnimacionComportamiento] = useState([
    {
      clase: 'animate__bounce',
      nombre: 'Salto'
    },
    {
      clase: 'animate__pulse',
      nombre: 'Pulso'
    },
    {
      clase: 'animate__shakeX',
      nombre: 'Sacudida Horizontal'
    },
    {
      clase: 'animate__shakeY',
      nombre: 'Sacudida Vertical'
    },
    {
      clase: 'animate__headShake',
      nombre: 'Vibración'
    }
  ]);

  const [listaAnimacionEntrada] = useState([
    {
      clase: 'animate__backInUp',
      nombre: 'Subida'
    },

    {
      clase: 'animate__bounceIn',
      nombre: 'Aparición'
    }
  ]);

  const [listaAnimacionSalida] = useState([
    {
      clase: 'animate__backOutDown',
      nombre: 'Bajada'
    },
    {
      clase: 'animate__bounceOut',
      nombre: 'Desaparición'
    }
  ]);

  const [listaRepeticion] = useState([
    {
      clase: 'animate__repeat-1',
      nombre: '1'
    },
    {
      clase: 'animate__repeat-2',
      nombre: '2'
    },
    {
      clase: 'animate__repeat-3',
      nombre: '3'
    },
    {
      clase: 'animate__infinite',
      nombre: 'Infinito'
    }
  ]);

  const [listaDuracion] = useState([
    {
      clase: 'animate__delay-2s',
      nombre: '2 Segundos'
    },
    {
      clase: 'animate__delay-3s',
      nombre: '3 Segundos'
    },
    {
      clase: 'animate__delay-4s',
      nombre: '4 Segundos'
    },
    {
      clase: 'animate__delay-5s',
      nombre: '5 Segundos'
    }
  ]);

  const [listaRetraso] = useState([
    {
      clase: 'animate__slow',
      nombre: 'Lento (2 Segundos)'
    },
    {
      clase: 'animate__slower',
      nombre: 'Medio (3 Segundos)'
    },
    {
      clase: 'animate__fast',
      nombre: 'Rápido (800 MiliSegundos)'
    },
    {
      clase: 'animate__faster',
      nombre: 'Más rápido (500 MiliSegundos)'
    }
  ]);

  const Interfaz = 'editor';

  useEffect(() => {
    if (cuenta.token !== '') {
      setCodigoPagina(parametros.codigo);
    } else {
      setElegido({
        nombre: '',
        estado: -1
      });
    }
  }, [cuenta.token, parametros.codigo]);

  useEffect(() => {
    if (codigopagina === '0') {
      setElegido({
        nombre: '',
        estado: -1
      });
    } else {
      recuperarPagina();
    }
  }, [codigopagina]);

  useEffect(() => {
    if (cuenta.token === '') {
      setVital(true);
    } else {
      setVital(false);
      recuperarPaginas();
      obtenerContenido();
      obtenerPromociones();
      obtenerMarcas();
      obtenerCategorias();
    }
  }, [cuenta]);

  // useEffect(() => {
  //   if (promocionId) {
  //     obtenerProductosDePromocion(promocionId);
  //   }
  // }, [promocionId]);

  useEffect(() => {
    if (elegido.nombre !== undefined) {
      if (elegido.nombre === '') {
        setActividad(true);
      } else {
        setActividad(false);
      }
    }
    if (elegido.secciones !== undefined) {
      if (elegido.secciones.length === 0) {
        setEstructura([]);
      } else {
        setEstructura(elegido.secciones);
      }
    } else {
      setEstructura([]);
    }
  }, [elegido]);

  useEffect(() => {
    if (tipo !== '') {
      formularioContenidos();
    }
  }, [tipo]);

  useEffect(() => {
    if (tipoContenido.length > 0) {
      cargarContenidos();
    }
  }, [tipoContenido]);

  useEffect(() => {
    if (derecho) {
      formularioContenidos();
    }
  }, [derecho]);

  useEffect(() => {
    if (!esCategoria && promocionId) {
      obtenerProductosDePromocion(promocionId);
    } else if (esCategoria) {
      setPromocionId(null); // Restablecer promocionId cuando esCategoria es true
    }
  }, [promocionId, esCategoria]);

  // Efecto para obtener categorías cuando esCategoria cambia a true
  useEffect(() => {
    if (esCategoria) {
      obtenerCategorias();
    }
  }, [esCategoria]);

  // Efecto para obtener productos de categoría cuando cambia categoriaId
  useEffect(() => {
    if (categoriaId) {
      obtenerProductosCategoria(categoriaId);
    }
  }, [categoriaId]);

  function elegir(codigo) {
    setCodigoPagina(codigo);
    navegar(`/editor/${codigo}`);
    cerrarIzquierdo();
  }

  async function recuperarPaginas() {
    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/paginas`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
      console.log('Datos  de la pagina obtenidos');
      setDatos(respuesta.data.arrayJson);
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'recuperarPaginas',
        `${error.toString()}`,
        {
          error
        },
        1
      );
    }
  }

  async function recuperarPagina() {
    setCarga(true);
    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/paginas/${codigopagina}?estado=0`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
      setElegido(respuesta.data.objetoJson);
      console.log('Datos obtenidos');
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'recuperarPagina',
        `${error.toString()}`,
        {
          codigopagina,
          error
        },
        1
      );
    }
    setCarga(false);
  }

  async function obtenerContenido() {
    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/recursos/contenido`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
      setContenidos(respuesta.data.objetoJson);
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'obtenerContenido',
        `${error.toString()}`,
        {
          error
        },
        1
      );
    }
  }

  async function obtenerPromociones() {
    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/consultas/promos?estado=`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
      setPromociones(respuesta.data.arrayJson);
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'obtenerPromociones',
        `${error.toString()}`,
        { error },
        1
      );
    }
  }

  async function obtenerProductosDePromocion(ruta) {
    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/productopromociones/?ruta=${ruta}`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });

      console.log('Productos obtenidos:', respuesta.data.arrayJson);
      setProductos(respuesta.data.arrayJson);
    } catch (error) {
      console.error('Error al obtener productos de la promoción:', error);
    }
  }

  async function obtenerProductosCategoria(ruta) {
    console.log('ruta:');
    console.log(ruta);

    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/productopromociones/productoCategoria/?ruta=${ruta}`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });

      console.log('Productos obtenidos:', respuesta.data.arrayJson);
      setProductos(respuesta.data.arrayJson);
    } catch (error) {
      console.error('Error al obtener productos de la promoción:', error);
    }
  }


  async function obtenerMarcas() {
    try {
      let respuesta = await Axios.post(`${urlEcommerceBackend}/recursos/marcas?dato=&categoria=&subcategoria=`);
      setMarcas(respuesta.data.arrayJson);
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'obtenerMarcas',
        `${error.toString()}`,
        {
          error
        },
        1
      );
    }
  }

  async function obtenerCategorias() {
    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/categorias`);
      setCategorias(respuesta.data.arrayJson);
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'obtenerCategorias',
        `${error.toString()}`,
        {
          error
        },
        1
      );
    }
  }

  function nuevo() {
    setAccion(1);
    mostrarModalPagina();
  }

  function modificar() {
    setAccion(2);
    mostrarModalPagina();
  }

  function actualizar() {
    console.log('Entra para actualizar la pagina ');

    recuperarPaginas();
    recuperarPagina(parametros.codigo);
  }

  function agregarSeccion() {
    if (elegido.nombre === undefined) {
      Swal.fire('Editor', 'Debe seleccionar una plantilla de Página para continuar', 'warning');
    } else {
      let informacion = [...estructura];
      informacion.push({
        id: informacion.length,
        nombre: '',
        ruta: '',
        tamano: 12,
        descrip: '',
        elementos: []
      });
      setEstructura(informacion);
    }
  }

  // function agregarElemento(tipo) {
  //   if (elegido.nombre === undefined) {
  //     cerrarInferior();
  //     Swal.fire('Editor', 'Debe seleccionar una plantilla de Página para continuar', 'warning');
  //   } else {
  //     if (tipo === 'Carta Simple') {
  //       let informacion = [...estructura];
  //       informacion[posicionS].elementos.push({
  //         id: informacion[posicionS].elementos.length,
  //         nombre: '',
  //         ruta: '',
  //         tipo: tipo,
  //         tamano: 12,
  //         descrip: '',
  //         configuracion: configDefecto,
  //         descriptivo: {
  //           nombre: false,
  //           contado: false,
  //           cuota: false
  //         },
  //         filtro: {
  //           origen: '',
  //           tipo: '',
  //           valor: '',
  //           dato: '',
  //           cantidad: 0
  //         },
  //         contenidos: []
  //       });
  //       setEstructura(informacion);
  //     } else if (tipo === 'Carta Multiple') {
  //       let informacion = [...estructura];
  //       informacion[posicionS].elementos.push({
  //         id: informacion[posicionS].elementos.length,
  //         nombre: '',
  //         ruta: '',
  //         tipo: tipo,
  //         tamano: 12,
  //         descrip: '',
  //         configuracion: configDefecto,
  //         descriptivo: {
  //           nombre: false,
  //           contado: false,
  //           cuota: false
  //         },
  //         filtro: {
  //           origen: '',
  //           tipo: '',
  //           valor: '',
  //           dato: '',
  //           cantidad: 0
  //         },
  //         contenidos: []
  //       });
  //       setEstructura(informacion);
  //     } else if (tipo === 'Videos') {
  //       let informacion = [...estructura];
  //       informacion[posicionS].elementos.push({
  //         id: informacion[posicionS].elementos.length,
  //         nombre: '',
  //         ruta: '',
  //         tipo: tipo,
  //         tamano: 12,
  //         descrip: '',
  //         configuracion: configDefecto,
  //         descriptivo: {
  //           nombre: false,
  //           contado: false,
  //           cuota: false
  //         },
  //         filtro: {
  //           origen: '',
  //           tipo: '',
  //           valor: '',
  //           dato: '',
  //           cantidad: 0
  //         },
  //         contenidos: []
  //       });
  //       setEstructura(informacion);
  //     } else {
  //       let informacion = [...estructura];
  //       informacion[posicionS].elementos.push({
  //         id: informacion[posicionS].elementos.length,
  //         nombre: '',
  //         ruta: '',
  //         tipo: tipo,
  //         tamano: 12,
  //         descrip: '',
  //         configuracion: configDefecto,
  //         descriptivo: {
  //           nombre: false,
  //           contado: false,
  //           cuota: false
  //         },
  //         filtro: {
  //           origen: '',
  //           tipo: '',
  //           valor: '',
  //           dato: '',
  //           cantidad: 0
  //         },
  //         contenidos: []
  //       });
  //       setEstructura(informacion);
  //     }
  //     cerrarInferior();
  //   }
  // }

  function agregarElemento(tipo, opciones = {}) {
    if (elegido.nombre === undefined) {
      cerrarInferior();
      Swal.fire('Editor', 'Debe seleccionar una plantilla de Página para continuar', 'warning');
    } else {
      let informacion = [...estructura];

      // Comprobar si el elemento ya existe
      const existeElemento = informacion[posicionS].elementos.some(el => el.tipo === tipo);

      if (!existeElemento) {
        if (tipo === 'Carta Simple') {
          informacion[posicionS].elementos.push({
            id: informacion[posicionS].elementos.length,
            nombre: '',
            ruta: '',
            tipo: tipo,
            tamano: 12,
            descrip: '',
            configuracion: configDefecto,
            descriptivo: {
              nombre: false,
              contado: false,
              cuota: false
            },
            filtro: {
              origen: '',
              tipo: '',
              valor: '',
              dato: '',
              cantidad: 0
            },
            contenidos: []
          });
        } else if (tipo === 'Carta Multiple') {
          informacion[posicionS].elementos.push({
            id: informacion[posicionS].elementos.length,
            nombre: '',
            ruta: '',
            tipo: tipo,
            tamano: 12,
            descrip: '',
            configuracion: configDefecto,
            descriptivo: {
              nombre: false,
              contado: false,
              cuota: false
            },
            filtro: {
              origen: '',
              tipo: '',
              valor: '',
              dato: '',
              cantidad: 0
            },
            contenidos: []
          });
        } else if (tipo === 'Videos') {
          informacion[posicionS].elementos.push({
            id: informacion[posicionS].elementos.length,
            nombre: '',
            ruta: '',
            tipo: tipo,
            tamano: 12,
            descrip: '',
            configuracion: configDefecto,
            descriptivo: {
              nombre: false,
              contado: false,
              cuota: false
            },
            filtro: {
              origen: '',
              tipo: '',
              valor: '',
              dato: '',
              cantidad: 0
            },
            contenidos: []
          });
        } else if (tipo === 'Carrusel Promociones') {
          // Usar opciones para este tipo específico de elemento
          informacion[posicionS].elementos.push({
            id: informacion[posicionS].elementos.length,
            nombre: opciones.nombre || '',
            ruta: opciones.ruta || '',
            tipo: tipo,
            tamano: opciones.tamano || 12,
            descrip: opciones.descrip || 'Carrusel de promociones con productos y tiempo de visualización',
            configuracion: opciones.configuracion || configDefecto,
            descriptivo: opciones.descriptivo || {
              nombre: false,
              contado: false,
              cuota: false
            },
            filtro: opciones.filtro || {
              origen: '',
              tipo: '',
              valor: '',
              dato: '',
              cantidad: 0
            },
            contenidos: opciones.contenidos || []
          });
        } else if (tipo === 'RelojContador') {
          const { fechaInicio, horaInicio, fechaFin, horaFin, color, tamano, fondo, descripcion } = opciones;

          // Crear el nuevo elemento RelojContador con la estructura correcta
          const nuevoRelojContador = {
            id: informacion[posicionS].elementos.length,
            nombre: 'Reloj Contador',
            tipo: 'RelojContador',
            fechaInicio: fechaInicio,
            horaInicio: horaInicio,
            fechaFin: fechaFin,
            horaFin: horaFin,
            tamano: tamano || 'grande', // Tamaño predeterminado "grande"
            color: color || '#f5a22e', // Color predeterminado
            fondo: fondo || '', // Fondo predeterminado como vacío
            descripcion: descripcion || 'Reloj configurado' // Descripción predeterminada
          };

          // Agregar el nuevo elemento RelojContador a la posición especificada en informacion
          informacion[posicionS].elementos.push(nuevoRelojContador);

          // Log para mostrar la estructura completa del nuevo elemento RelojContador
          console.log('Estructura del nuevo elemento RelojContador:', nuevoRelojContador);
          console.log('Estrutura luego de agrega el elemento RelojContador: ', estructura);

        } else {
          informacion[posicionS].elementos.push({
            id: informacion[posicionS].elementos.length,
            nombre: '',
            ruta: '',
            tipo: tipo,
            tamano: 12,
            descrip: '',
            configuracion: configDefecto,
            descriptivo: {
              nombre: false,
              contado: false,
              cuota: false
            },
            filtro: {
              origen: '',
              tipo: '',
              valor: '',
              dato: '',
              cantidad: 0
            },
            contenidos: []
          });
        }
        setEstructura(informacion);
        cerrarInferior();
      } else {
        console.log(`El elemento de tipo ${tipo} ya existe en la posición ${posicionS}. No se agregará de nuevo.`);
        Swal.fire('Editor', `El elemento de tipo ${tipo} ya existe y no se puede agregar nuevamente.`, 'info');
      }
    }
  }




  function cerrarPagina() {
    cerrarModalPagina();
  }

  async function guardarPagina() {
    if (document.getElementById('txtNombre').value === '') {
      Swal.fire('Atención', 'El campo Nombre esta vacio', 'info');
    } else {
      if (accion === 1) {
        let parametros = {
          nombre: document.getElementById('txtNombre').value
        };
        try {
          let respuesta = await Axios.post(`${urlEcommerceBackend}/paginas`, parametros, {
            headers: {
              authorization: `Bearer ${cuenta.token}`
            }
          });
          document.getElementById('txtNombre').value = '';
          actualizar();
          cerrarModalPagina();
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: 'success',
            title: respuesta.data.descripcion
          });
        } catch (error) {
          await reportar(
            `${cuenta.nombre} ${cuenta.apellido}`,
            'Ecommerce Admin',
            'Frontend',
            'editor.jsx',
            'guardarPagina',
            `${error.toString()}`,
            {
              error
            },
            1
          );
        }
      } else if (accion === 2) {
        let parametros = {
          nombre: document.getElementById('txtNombre').value
        };
        try {
          let respuesta = await Axios.put(`${urlEcommerceBackend}/paginas/${elegido.codigo}`, parametros, {
            headers: {
              authorization: `Bearer ${cuenta.token}`
            }
          });
          document.getElementById('txtNombre').value = '';
          actualizar();
          elegir(codigopagina);
          cerrarModalPagina();
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: 'success',
            title: respuesta.data.descripcion
          });
        } catch (error) {
          await reportar(
            `${cuenta.nombre} ${cuenta.apellido}`,
            'Ecommerce Admin',
            'Frontend',
            'editor.jsx',
            'guardarPagina',
            `${error.toString()}`,
            {
              error
            },
            1
          );
        }
      }
    }
  }

  // function indiceElemento(posicionS, tamano, valor, descripcion, posicionE) {
  //   if (tamano === 1 && valor === -1) {
  //   } else if (tamano === 12 && valor === 1) {
  //   } else {
  //     let informacion = [...estructura];
  //     informacion[posicionS].elementos[posicionE].descrip = descripcion;
  //     informacion[posicionS].elementos[posicionE].tamano = valor;
  //     setEstructura(informacion);
  //   }
  // }

  function indiceElemento(posicionS, tamano, valor, descripcion, posicionE) {
    if (tamano === 1 && valor === -1) {
    } else if (tamano === 12 && valor === 1) {
    } else {
      let informacion = [...estructura];
      informacion[posicionS].elementos[posicionE].descrip = descripcion;
      informacion[posicionS].elementos[posicionE].tamano = valor;
      setEstructura(informacion);
    }
  }
  function quitarSeccion(idS) {
    Swal.fire({
      title: 'Desea eliminar la Sección?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        setEstructura(estructura.filter((a) => a.id !== idS));
      }
    });
  }

  function quitarElemento(posicion, id) {
    Swal.fire({
      title: 'Desea eliminar el Elemento?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        let informacion = [...estructura];

        // Obtener el elemento que se eliminará para saber su tipo
        const elementoEliminado = informacion[posicion].elementos.find((a) => a.id === id);

        // Eliminar el elemento de la estructura
        informacion[posicion].elementos = informacion[posicion].elementos.filter((a) => a.id !== id);

        console.log('informacion');
        console.log(informacion);


        setEstructura(informacion);

        // Log del tipo de elemento eliminado y limpieza de configuración asociada
        if (elementoEliminado) {
          console.log('El elemento que se eliminó es de tipo:', elementoEliminado.tipo);

          // Limpiar configuración asociada si es RelojContador o CarruselPromociones
          if (elementoEliminado.tipo === 'RelojContador') {
            setRelojConfiguracion(null);
            console.log('relojConfiguracion');
            console.log(relojConfiguracion);

          } else if (elementoEliminado.tipo === 'CarruselPromociones') {
            setCarruselConfiguracion(null);
            console.log('carruselConfiguracion');
            console.log(carruselConfiguracion);

          }
        } else {
          console.log('No se encontró el elemento a eliminar.');
        }
      }
    });
  }


  // async function aplicar() {
  //   if (elegido.codigo !== undefined) {
  //     if (validarSitio(estructura)) {
  //       let parametros = {
  //         secciones: estructura
  //       };
  //       try {
  //         let respuesta = await Axios.put(`${urlEcommerceBackend}/paginas/${elegido.codigo}`, parametros, {
  //           headers: {
  //             authorization: `Bearer ${cuenta.token}`
  //           }
  //         });
  //         actualizar();
  //         const Toast = Swal.mixin({
  //           toast: true,
  //           position: 'bottom-end',
  //           showConfirmButton: false,
  //           timer: 3000,
  //           timerProgressBar: true,
  //           didOpen: (toast) => {
  //             toast.addEventListener('mouseenter', Swal.stopTimer);
  //             toast.addEventListener('mouseleave', Swal.resumeTimer);
  //           }
  //         });
  //         Toast.fire({
  //           icon: 'success',
  //           title: respuesta.data.descripcion
  //         });
  //       } catch (error) {
  //         await reportar(
  //           `${cuenta.nombre} ${cuenta.apellido}`,
  //           'Ecommerce Admin',
  //           'Frontend',
  //           'editor.jsx',
  //           'aplicar',
  //           `${error.toString()}`,
  //           {
  //             error
  //           },
  //           1
  //         );
  //       }
  //     }
  //   } else {
  //     Swal.fire('Editor', 'No se detectan cambios', 'error');
  //   }
  // }

  // actaul ---async function aplicar() {
  //   if (elegido.codigo !== undefined) {
  //     if (validarSitio(estructura)) {
  //       console.log('Valida el sitio');

  //       // Crear copia de la estructura actual
  //       const nuevaEstructura = [...estructura];

  //       // Función para agregar un elemento a la estructura
  //       const agregarElemento = (elemento) => {
  //         nuevaEstructura[posicionS].elementos.push(elemento);
  //       };

  //       // Agregar configuración de carrusel si existe
  //       if (carruselConfiguracion) {
  //         console.log('Configurando carrusel con:', carruselConfiguracion);
  //         const carruselElemento = {
  //           id: 0, // Asegúrate de que este ID sea único o se ajuste a la lógica del backend
  //           nombre: '',
  //           tipo: 'CarruselPromociones',
  //           configuracion: carruselConfiguracion,
  //           descripcion: '',
  //         };
  //         agregarElemento(carruselElemento);
  //       }

  //       // Agregar configuración de reloj si existe
  //       if (relojConfiguracion) {
  //         console.log('Configurando reloj con:', relojConfiguracion);
  //         const relojElemento = {
  //           id: 0, // Asigna un ID único
  //           nombre: 'Reloj Contador sin nombre',
  //           tipo: 'RelojContador',
  //           configuracion: relojConfiguracion,
  //           descripcion: 'Reloj configurado en la posición 0',
  //         };
  //         agregarElemento(relojElemento);
  //       }

  //       // Actualizar parámetros con la nueva estructura
  //       const parametros = {
  //         secciones: nuevaEstructura
  //       };

  //       console.log('parametros');
  //       console.log(parametros);

  //       try {
  //         let respuesta = await Axios.put(`${urlEcommerceBackend}/paginas/${elegido.codigo}`, parametros, {
  //           headers: {
  //             authorization: `Bearer ${cuenta.token}`
  //           }
  //         });
  //         actualizar();
  //         const Toast = Swal.mixin({
  //           toast: true,
  //           position: 'bottom-end',
  //           showConfirmButton: false,
  //           timer: 3000,
  //           timerProgressBar: true,
  //           didOpen: (toast) => {
  //             toast.addEventListener('mouseenter', Swal.stopTimer);
  //             toast.addEventListener('mouseleave', Swal.resumeTimer);
  //           }
  //         });
  //         Toast.fire({
  //           icon: 'success',
  //           title: respuesta.data.descripcion
  //         });

  //       } catch (error) {
  //         await reportar(
  //           `${cuenta.nombre} ${cuenta.apellido}`,
  //           'Ecommerce Admin',
  //           'Frontend',
  //           'editor.jsx',
  //           'aplicar',
  //           `${error.toString()}`,
  //           {
  //             error
  //           },
  //           1
  //         );
  //       }
  //     } else {
  //       console.log('No valida el sitio');
  //       Swal.fire('Editor', 'No se detectan cambios', 'error');
  //     }
  //   } else {
  //     console.log('no elegido');
  //     Swal.fire('Editor', 'No se detectan cambios', 'error');
  //   }
  // }

  const subirImagen = async (base64Image, nombreArchivo, formatoArchivo) => {
    try {

      // Preparar FormData para enviar la imagen al backend
      const formData = new FormData();
      formData.append('file', base64Image); // Imagen en base64
      formData.append('codigo', codigopagina); // Usar el estado codigopagina
      formData.append('formato', formatoArchivo); // Formato de la imagen (por ejemplo: jpg, png)

      // Realizar la solicitud POST para subir la imagen
      const response = await Axios.post(`${urlEcommerceBackend}/almacen/subirImagenReloj`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${cuenta.token}`,
        }
      });

      // Retornar la URL de la imagen subida desde la respuesta
      return response.data.objetoJson?.url;
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      Swal.fire('Error', 'No se pudo subir la imagen.', 'error');
      throw new Error('Error al subir la imagen');
    }
  };


  async function aplicar() {
    if (elegido.codigo !== undefined) {
      if (validarSitio(estructura)) {
        console.log('Valida el sitio');
        console.log('Estructura inicial');
        console.log(estructura);

        // Crear una nueva estructura a partir de la estructura actual
        const nuevaEstructura = JSON.parse(JSON.stringify(estructura));  // Usar JSON para copiar completamente la estructura

        // Función para agregar o actualizar un elemento en la estructura
        const agregarElemento = (elemento) => {
          // Validar que el tipo del elemento sea 'RelojContador' o 'CarruselPromociones'
          if (elemento.tipo === 'RelojContador' || elemento.tipo === 'CarruselPromociones') {
            // Buscar el índice del elemento si ya existe en la estructura
            const indiceElementoExistente = nuevaEstructura[posicionS].elementos.findIndex(el => el.tipo === elemento.tipo);

            if (indiceElementoExistente === -1) {
              // Si no existe, agregarlo
              console.log(`El elemento de tipo ${elemento.tipo} no existe, se agregará.`);
              nuevaEstructura[posicionS].elementos.push(elemento);
            } else {
              // Si ya existe, actualizar el elemento
              console.log(`El elemento de tipo ${elemento.tipo} ya existe, se actualizará.`);
              nuevaEstructura[posicionS].elementos[indiceElementoExistente] = { ...nuevaEstructura[posicionS].elementos[indiceElementoExistente], ...elemento };
            }
          } else {
            console.log(`El elemento de tipo ${elemento.tipo} no es válido para ser actualizado.`);
          }
        };


        // Agregar configuración de carrusel si existe
        if (carruselConfiguracion) {
          console.log('Configurando carrusel con:', carruselConfiguracion);
          const carruselElemento = {
            ...carruselConfiguracion,
            id: nuevaEstructura[posicionS].elementos.length,
            tipo: 'CarruselPromociones',
          };
          agregarElemento(carruselElemento);
        }

        // Agregar configuración de reloj si existe y no fue eliminado
        if (relojConfiguracion) {
          console.log('Configurando reloj con:', relojConfiguracion);

          let imagenUrl = null;

          // Verificar el valor de fondo antes de procesarlo
          if (relojConfiguracion.fondo && relojConfiguracion.fondo.startsWith('data:image')) {
            if (relojConfiguracion.fondo.length > 100000) {
              console.log('La imagen es demasiado grande');
              Swal.fire('Error', 'La imagen es demasiado grande. No se puede aplicar.', 'error');
              return;
            }
            // Intentar subir la imagen y asignar la URL resultante a `imagenUrl`
            try {
              imagenUrl = await subirImagen(relojConfiguracion.fondo);
              console.log('URL de imagen subida:', imagenUrl);
            } catch (error) {
              console.log('Error al subir la imagen:', error);
              Swal.fire('Error', 'No se pudo subir la imagen.', 'error');
              return;
            }
          } else {
            // Si `fondo` ya es una URL o es una cadena vacía
            imagenUrl = relojConfiguracion.fondo || '';
            console.log('Valor de imagenUrl asignado directamente:', imagenUrl);
          }

          // Crear el objeto `relojElemento` con `fondo` usando `imagenUrl`
          const relojElemento = {
            id: nuevaEstructura[posicionS].elementos.length,
            nombre: relojConfiguracion.nombre || 'Reloj Contador',
            tipo: 'RelojContador',
            color: relojConfiguracion.color || '#000000',
            descripcion: relojConfiguracion.descripcion || 'Reloj configurado en la posición ' + posicionS,
            fechaInicio: relojConfiguracion.fechaInicio || '',
            fechaFin: relojConfiguracion.fechaFin || '',
            horaInicio: relojConfiguracion.horaInicio || '',
            horaFin: relojConfiguracion.horaFin || '',
            fondo: imagenUrl,
            tamano: relojConfiguracion.tamano || 'grande',
          };

          console.log('Valor final de imagenUrl en relojElemento.fondo:', relojElemento.fondo);
          console.log('relojElemento final:', relojElemento);

          agregarElemento(relojElemento);
        }

        console.log('Estructura después de agregar relojElemento:', nuevaEstructura);
        const parametros = { secciones: nuevaEstructura };
        console.log('parametros', parametros);

        try {
          // Llamar a la API para insertar la estructura completa
          console.log('parametros antes de enviar:', parametros);
          let respuesta = await Axios.put(`${urlEcommerceBackend}/paginas/${elegido.codigo}`, parametros, {
            headers: {
              authorization: `Bearer ${cuenta.token}`
            }
          });
          actualizar();
          Swal.fire({
            icon: 'success',
            title: respuesta.data.descripcion,
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: 'success',
            title: respuesta.data.descripcion
          });
        } catch (error) {
          await reportar(
            `${cuenta.nombre} ${cuenta.apellido}`,
            'Ecommerce Admin',
            'Frontend',
            'editor.jsx',
            'aplicar',
            `${error.toString()}`,
            { error },
            1
          );
        }
      } else {
        console.log('No valida el sitio');
        Swal.fire('Editor', 'No se detectan cambios', 'error');
      }
    } else {
      console.log('no elegido');
      Swal.fire('Editor', 'No se detectan cambios', 'error');
    }
  }

  function validarSitio(sitio) {
    let envio = false;
    let seccionTamano = 0;
    let elementoTamano = 0;
    let elementoInterno = 0;

    sitio.forEach((seccion) => {
      seccionTamano += seccion.tamano;

      if (seccionTamano === 12) {
        seccionTamano = 0;
      }

      if (seccion.elementos.length === 0) {
        console.log('Llega aqui  2');
        elementoInterno++;
      }

      seccion.elementos.forEach((elemento) => {
        elementoTamano += elemento.tamano || 0;
        if (elementoTamano === 12) {
          elementoTamano = 0;
        }
      });
    });

    if (seccionTamano > 0) {
      envio = false;
      Swal.fire('Editor', 'Se detectan espacios vacios entre Secciones', 'warning');
    } else if (elementoTamano > 0) {
      envio = false;
      Swal.fire('Editor', 'Se detectan espacios vacios entre Elementos', 'warning');
    } else if (elementoInterno > 0) {
      envio = false;
      Swal.fire('Editor', 'Se detectan Secciones sin Elementos', 'warning');
    } else {
      envio = true;
    }

    return envio;
  }


  // function validarSitio(sitio) {
  //   let envio = false;
  //   let seccionTamano = 0;
  //   let elementoTamano = 0;
  //   let elementoInterno = 0;

  //   sitio.forEach((seccion) => {
  //     seccionTamano += seccion.tamano;
  //     console.log('Tamaño de sección:', seccionTamano);

  //     if (seccionTamano === 12) {
  //       seccionTamano = 0;
  //     }

  //     if (seccion.elementos.length === 0) {
  //       elementoInterno++;
  //     }

  //     seccion.elementos.forEach((elemento) => {

  //       elementoTamano += elemento.tamano;
  //       console.log('Elemento actual:', elemento);
  //       console.log('Tamaño de elemento actual:', elemento.tamano);
  //       console.log('Tamaño acumulado de elementos:', elementoTamano);

  //       if (elementoTamano === 12) {
  //         elementoTamano = 0;
  //       }
  //     });
  //   });

  //   console.log('Secciones sin elementos:', elementoInterno);

  //   console.log('Valor final de seccionTamano:', seccionTamano);
  //   console.log('Valor final de elementoTamano:', elementoTamano);
  //   console.log('Valor de elementoInterno:', elementoInterno);

  //   if (seccionTamano > 0) {
  //     envio = false;
  //     Swal.fire('Editor', 'Se detectan espacios vacios entre Secciones', 'warning');
  //   } else if (elementoTamano > 0) {
  //     envio = false;
  //     Swal.fire('Editor', 'Se detectan espacios vacios entre Elementos', 'warning');
  //   } else if (elementoInterno > 0) {
  //     envio = false;
  //     Swal.fire('Editor', 'Se detectan Secciones sin Elementos', 'warning');
  //   } else {
  //     envio = true;
  //   }

  //   return envio;
  // }

  function previa() {
    window.open(urlEcommerceFrontend + '/preview/' + codigopagina, '_blank');
  }

  function eliminar() {
    Swal.fire({
      icon: 'question',
      title: 'Esta seguro/a de eliminar esta Página?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let respuesta = await Axios.delete(`${urlEcommerceBackend}/paginas/${elegido.codigo}`, {
            headers: {
              authorization: `Bearer ${cuenta.token}`
            }
          });
          setElegido({
            nombre: '',
            estado: -1
          });
          setEstructura([]);
          navegar('/editor/0');
          recuperarPaginas();
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: 'success',
            title: respuesta.data.descripcion
          });
        } catch (error) {
          await reportar(
            `${cuenta.nombre} ${cuenta.apellido}`,
            'Ecommerce Admin',
            'Frontend',
            'editor.jsx',
            'eliminar',
            `${error.toString()}`,
            {
              error
            },
            1
          );
        }
      }
    });
  }

  function establecerPrincipal() {
    if (elegido.codigo === undefined) {
      Swal.fire('Editor', 'Seleccione una plantilla para realizar esta acción', 'error');
    } else {
      Swal.fire({
        icon: 'question',
        title: 'Esta seguro/a de habilitar esta página como la principal?',
        showDenyButton: true,
        confirmButtonText: 'Si',
        denyButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let parametros = {
            estado: 1
          };
          try {
            let respuesta = await Axios.put(`${urlEcommerceBackend}/paginas/${elegido.codigo}`, parametros, {
              headers: {
                authorization: `Bearer ${cuenta.token}`
              }
            });
            actualizar();
            elegir(elegido.codigo);
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: 'success',
              title: respuesta.data.descripcion
            });
          } catch (error) {
            await reportar(
              `${cuenta.nombre} ${cuenta.apellido}`,
              'Ecommerce Admin',
              'Frontend',
              'editor.jsx',
              'establecerPrincipal',
              `${error.toString()}`,
              {
                error
              },
              1
            );
          }
        }
      });
    }
  }

  function cambiarNE(posicion1, posicion2) {
    setPosicionS(posicion1);
    setPosicionE(posicion2);
    mostrarModalNE();
  }

  function cerrarNE() {
    cerrarModalNE();
  }

  function guardarNE() {
    estructura[posicionS].elementos[posicionE].nombre = document.getElementById('txtNombreE').value;
    cerrarModalNE();
  }

  const eventoEnterNE = (event) => {
    if (event.key === 'Enter') {
      guardarNE();
    }
  };

  const eventoEnterNP = (event) => {
    if (event.key === 'Enter') {
      guardarPagina();
    }
  };

  function establecerContenido(posicion1, posicion2, tipos) {
    setPosicionS(posicion1);
    setPosicionE(posicion2);
    setTipo(tipos);
    mostrarDerecho();
  }

  async function formularioContenidos() {
    if (tipo === 'Carta Simple') {
      setOrigenContenido(['Producto']);
      setTipoContenido(['Producto', 'Promoción', 'Marca', 'Categoria', 'Subcategoria']);
      setCantidadContenido(['1']);
    } else if (tipo === 'Carta Multiple') {
      setOrigenContenido(['Producto', 'Más vendidos']);
      setTipoContenido(['Promoción', 'Marca', 'Categoria', 'Subcategoria']);
      setCantidadContenido(['4']);
    } else if (tipo === 'Carrusel Regular') {
      setOrigenContenido(['Producto', 'Más vendidos']);
      setTipoContenido(['Promoción', 'Marca', 'Categoria', 'Subcategoria']);
      setCantidadContenido(['6', '7', '8', '9', '10', '11', '12', '13', '14', '15']);
    } else if (tipo === 'Carrusel Promociones') {
      setOrigenContenido(['Producto', 'Promoción']);
      setTipoContenido(['Producto', 'Promoción', 'Marca', 'Categoria', 'Subcategoria']);
      setCantidadContenido(['3', '4', '5', '6', '7', '8', '9', '10']);
    } else if (tipo === 'Banner') {
      setOrigenContenido(['Banner']);
      setTipoContenido([]);
      setValorContenido([]);
      setCantidadContenido([]);
    } else if (tipo === 'Videos') {
      const { data } = await Axios.get(`${urlEcommerceBackend}/videos`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
      const nombres = data.arrayJson.map(video => video.nombre);

      setOrigenContenido(['Videos']);
      setTipoContenido([]);
      setValorContenido(nombres);
      setCantidadContenido([]);
    }
  }


  function cargarContenidos() {
    if (document.getElementById('cbOrigen').value === 'producto') {
      if (document.getElementById('cbTipo').value === 'marca') {
        setValorContenido(marcas);
      } else if (document.getElementById('cbTipo').value === 'promoción') {
        setValorContenido(promociones);
      } else if (document.getElementById('cbTipo').value === 'categoria') {
        setValorContenido(categorias);
      } else if (document.getElementById('cbTipo').value === 'subcategoria') {
        let subcategorias = [];
        categorias.forEach((categoria) => {
          categoria.subcategoria.forEach((sub) => {
            subcategorias.push(sub);
          });
        });
        setValorContenido(subcategorias);
      } else if (document.getElementById('cbTipo').value === 'producto') {
        setValorContenido(contenidos.productos);
      }
    } else if (document.getElementById('cbOrigen').value === 'promoción') {
      if (document.getElementById('cbTipo').value === 'promoción') {
        setValorContenido(contenidos.promociones);
      }
    } else if (document.getElementById('cbOrigen').value === 'banner') {
      if (document.getElementById('cbTipo').value === 'promoción') {
        setValorContenido(contenidos.banners);
      }
    } else if (document.getElementById('cbOrigen').value === 'videos') {
      if (document.getElementById('cbTipo').value === 'promoción') {
        setValorContenido(contenidos.videos);
      }
    }
  }

  function guardarContenido() {
    estructura[posicionS].elementos[posicionE].filtro.origen = document.getElementById('cbOrigen').value;
    estructura[posicionS].elementos[posicionE].filtro.tipo = document.getElementById('cbTipo').value;
    estructura[posicionS].elementos[posicionE].filtro.valor = document.getElementById('cbValor').value;
    estructura[posicionS].elementos[posicionE].filtro.dato = '';
    estructura[posicionS].elementos[posicionE].filtro.cantidad = document.getElementById('cbCantidad').value;
    cerrarDerecho();
  }

  function descriptivo(tipo, posS, posE) {
    if (tipo === 1) {
      if (estructura[posS].elementos[posE].descriptivo.nombre) {
        estructura[posS].elementos[posE].descriptivo.nombre = false;
      } else {
        estructura[posS].elementos[posE].descriptivo.nombre = true;
      }
    } else if (tipo === 2) {
      if (estructura[posS].elementos[posE].descriptivo.contado) {
        estructura[posS].elementos[posE].descriptivo.contado = false;
      } else {
        estructura[posS].elementos[posE].descriptivo.contado = true;
      }
    } else if (tipo === 3) {
      if (estructura[posS].elementos[posE].descriptivo.cuota) {
        estructura[posS].elementos[posE].descriptivo.cuota = false;
      } else {
        estructura[posS].elementos[posE].descriptivo.cuota = true;
      }
    }
  }

  function configurar(llaveSeccion, llaveElemento) {
    setPosicionS(llaveSeccion);
    setPosicionE(llaveElemento);
    //console.log();
    //setTipoConfig(1);
    setConfiguracion(estructura[llaveSeccion].elementos[llaveElemento].configuracion);
    mostrarModalConfig();
  }

  function cerrarConfig() {
    cerrarModalConfig();
  }

  function guardarConfig() {
    let nuevaEstructura = [...estructura];
    nuevaEstructura[posicionS].elementos[posicionE].configuracion = {
      elemento: {
        titulo: {
          tamano: parseInt(document.getElementById('tituloTamanoElemento').value),
          color: document.getElementById('tituloColorElemento').value
        },
        fondo: {
          color: document.getElementById('fondoColorElemento').value
        },
        borde: {
          tamano: parseInt(document.getElementById('bordeTamanoElemento').value),
          redondeo: parseInt(document.getElementById('bordeRedondeoElemento').value),
          color: document.getElementById('bordeColorElemento').value
        },
        efecto: {
          comportamiento: {
            animacion: document.getElementById('efectoAnimacionComportamientoElemento').value,
            repeticion: document.getElementById('efectoRepeticionComportamientoElemento').value,
            duracion: document.getElementById('efectoDuracionComportamientoElemento').value,
            retraso: document.getElementById('efectoRetrasoComportamientoElemento').value
          },
          entrada: {
            animacion: document.getElementById('efectoAnimacionEntradaElemento').value,
            repeticion: document.getElementById('efectoRepeticionEntradaElemento').value,
            duracion: document.getElementById('efectoDuracionEntradaElemento').value,
            retraso: document.getElementById('efectoDuracionEntradaElemento').value
          },
          salida: {
            animacion: document.getElementById('efectoAnimacionSalidaElemento').value,
            repeticion: document.getElementById('efectoRepeticionSalidaElemento').value,
            duracion: document.getElementById('efectoDuracionSalidaElemento').value,
            retraso: document.getElementById('efectoRetrasoSalidaElemento').value
          }
        }
      },
      contenido: {
        fondo: {
          color: document.getElementById('fondoColorContenido').value
        },
        borde: {
          tamano: parseInt(document.getElementById('bordeTamanoContenido').value),
          redondeo: parseInt(document.getElementById('bordeRedondeoContenido').value),
          color: document.getElementById('bordeColorContenido').value
        },
        texto: {
          tamano: parseInt(document.getElementById('textoTamanoContenido').value),
          color: document.getElementById('textoColorContenido').value
        },
        icono: {
          tamano: parseInt(document.getElementById('iconoTamanoContenido').value),
          color: document.getElementById('iconoColorContenido').value
        },
        sello: {
          tamano: parseInt(document.getElementById('selloTamanoContenido').value)
        },
        efecto: {
          comportamiento: {
            animacion: document.getElementById('efectoAnimacionComportamientoContenido').value,
            repeticion: document.getElementById('efectoRepeticionComportamientoContenido').value,
            duracion: document.getElementById('efectoDuracionComportamientoContenido').value,
            retraso: document.getElementById('efectoRetrasoComportamientoContenido').value
          },
          entrada: {
            animacion: document.getElementById('efectoAnimacionEntradaContenido').value,
            repeticion: document.getElementById('efectoRepeticionEntradaContenido').value,
            duracion: document.getElementById('efectoDuracionEntradaContenido').value,
            retraso: document.getElementById('efectoRetrasoEntradaContenido').value
          },
          salida: {
            animacion: document.getElementById('efectoAnimacionSalidaContenido').value,
            repeticion: document.getElementById('efectoRepeticionSalidaContenido').value,
            duracion: document.getElementById('efectoDuracionSalidaContenido').value,
            retraso: document.getElementById('efectoRetrasoSalidaContenido').value
          }
        }
      }
    };
    setEstructura(nuevaEstructura);
    cerrarModalConfig();
  }

  const [carruseles, setCarruseles] = useState([]);

  // const asociarContador = (carruselId, fechaInicio, horaInicio, fechaFin, horaFin, tamano, color, fondo) => {
  //   const carruselSeleccionado = carruseles.find(carrusel => carrusel.id === carruselId);

  //   if (!carruselSeleccionado) {
  //     //alert('Carrusel no encontrado.');
  //     Swal.fire('Editor', 'Carrusel no encontrado.', 'error');
  //     return;
  //   }

  //   // Lógica para asociar el reloj con el carrusel
  //   const nuevoElemento = {
  //     id: estructura[posicionS].elementos.length,
  //     nombre: `Reloj Contador ${carruselSeleccionado.nombre}`,
  //     tipo: 'RelojContador',
  //     carruselId: carruselSeleccionado.id,
  //     configuracion: {
  //       fechaInicio,
  //       horaInicio,
  //       fechaFin,
  //       horaFin,
  //       tamano,
  //       color,
  //       fondo: fondo || null,
  //     },
  //     descripcion: `Reloj asociado al carrusel ${carruselSeleccionado.nombre}`,
  //   };

  //   // Guardar la configuración en el estado o contexto
  //   setRelojConfiguracion(nuevoElemento);
  //   console.log('Reloj contador asociado al carrusel:', nuevoElemento);
  // };

  // const asociarContador = (carruselId, fechaInicio, horaInicio, fechaFin, horaFin, tamano, color, fondo) => {
  //   const carruselSeleccionado = carruseles.find(carrusel => carrusel.id === carruselId);
  //   if (!carruselSeleccionado) {
  //     Swal.fire('Editor', 'Carrusel no encontrado.', 'error');
  //     return;
  //   }

  //   const nuevoElemento = {
  //     id: estructura[posicionS].elementos.length,
  //     nombre: `Reloj Contador ${carruselSeleccionado.nombre}`,
  //     tipo: 'RelojContador',
  //     carruselId: carruselSeleccionado.id,
  //     configuracion: {
  //       fechaInicio,
  //       horaInicio,
  //       fechaFin,
  //       horaFin,
  //       tamano,
  //       color,
  //       fondo: fondo || null,
  //     },
  //     descripcion: `Reloj asociado al carrusel ${carruselSeleccionado.nombre}`,
  //   };

  //   setRelojConfiguracion(nuevoElemento);
  //   console.log('Reloj contador asociado al carrusel:', nuevoElemento);
  // };

  return (
    <>
      <div className='container-fluid mt-3 mb-3'>
        <div className='row mb-3'>
          <div className='col-md-12'>
            <h1>{carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faCode} />}Editor</h1>
          </div>
          <div className='col-md-12 mt-2'>
            <BarraNavegacion
              paginas={[
                {
                  direccion: '/principal',
                  nombre: 'Principal'
                },
                {
                  direccion: '/editor/0',
                  nombre: 'Editor'
                }
              ]}
            />
          </div>
          <div className='col-md-12 mt-3 mb-3'>
            <Card border='dark'>
              <Card.Body>
                <Card.Title>
                  <h5>
                    <FontAwesomeIcon icon={faFileCode} />
                    <u>Plantilla:</u>{' '}
                    {elegido.nombre === undefined || elegido.nombre === '' ? 'Seleccione plantilla' : elegido.nombre}
                    {elegido.estado === 1 ? (
                      <Badge bg='success'>
                        <FontAwesomeIcon icon={faCheck} />
                        Principal
                      </Badge>
                    ) : (
                      ''
                    )}
                  </h5>
                </Card.Title>
                <Dropdown>
                  <Dropdown.Toggle variant='outline-dark' id='dropdown-basic'>
                    <FontAwesomeIcon icon={faCogs} />
                    Opciones
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant='dark'>
                    <Dropdown.Item onClick={mostrarIzquierdo} disabled={vital}>
                      <FontAwesomeIcon icon={faFileCode} />
                      Plantillas <Badge bg='primary'>{datos.length}</Badge>
                    </Dropdown.Item>
                    {obtenerPermiso(cuenta.interfaces, Interfaz, 'modificar') ? (
                      <>
                        <Dropdown.Item onClick={agregarSeccion} disabled={actividad}>
                          <FontAwesomeIcon icon={faPager} />
                          Agregar Sección
                        </Dropdown.Item>
                        <Dropdown.Item onClick={modificar} disabled={actividad}>
                          <FontAwesomeIcon icon={faEdit} />
                          Modificar Nombre
                        </Dropdown.Item>
                        <Dropdown.Item onClick={aplicar} disabled={actividad}>
                          <FontAwesomeIcon icon={faCheck} />
                          Aplicar cambios
                        </Dropdown.Item>
                      </>
                    ) : (
                      ''
                    )}
                    <Dropdown.Item onClick={previa} disabled={actividad}>
                      <FontAwesomeIcon icon={faEye} />
                      Vista Previa
                    </Dropdown.Item>
                    {obtenerPermiso(cuenta.interfaces, Interfaz, 'modificar') ? (
                      elegido.estado !== undefined && elegido.estado === 0 ? (
                        <>
                          <Dropdown.Divider></Dropdown.Divider>
                          <Dropdown.Item onClick={establecerPrincipal} disabled={vital}>
                            <FontAwesomeIcon icon={faFileCircleCheck} />
                            Establecer como Principal
                          </Dropdown.Item>
                        </>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                    {obtenerPermiso(cuenta.interfaces, Interfaz, 'eliminar') ? (
                      <>
                        <Dropdown.Divider></Dropdown.Divider>
                        <Dropdown.Item onClick={eliminar} disabled={actividad}>
                          <FontAwesomeIcon icon={faTrash} />
                          Eliminar Página
                        </Dropdown.Item>
                      </>
                    ) : (
                      ''
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Row className='mt-2 mb-2'>
                  {estructura.map((seccion, keys) => (
                    <Col md={seccion.tamano} key={keys} className='mb-2 mt-1'>
                      <Card bg='light' border='primary' text='primary'>
                        <Card.Body>
                          <Card.Title>
                            <h6>
                              <FontAwesomeIcon icon={faPager} />
                              <u>
                                Sección {keys + 1} {seccion.descuento !== undefined ? '(Promoción)' : ''}
                              </u>{' '}
                              {seccion.nombre}
                            </h6>
                            {seccion.descuento !== undefined ? (
                              ''
                            ) : (
                              <Dropdown>
                                <Dropdown.Toggle variant='outline-primary'>
                                  <FontAwesomeIcon icon={faCog} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu variant='dark'>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setPosicionS(keys);
                                      mostrarInferior();
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faToolbox} /> Elemento
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    onClick={() => {
                                      quitarSeccion(seccion.id);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                    Eliminar
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </Card.Title>
                          <Row>
                            {seccion.elementos.map((elemento, keye) => (
                              <Col md={elemento.tamano} key={keye} className='mb-2'>
                                {elemento.tipo === 'RelojContador' ? (
                                  <Card bg="light" border="success" text="success">
                                    <Card.Body>
                                      <Card.Title>
                                        <h6>
                                          <FontAwesomeIcon icon={faToolbox} />
                                          <u>Elemento {keye + 1}</u>: {elemento.nombre}{' '}
                                          <Badge bg="success">{elemento.tipo}</Badge>
                                        </h6>
                                      </Card.Title>
                                      <Card.Body>
                                        <Row>
                                          <Row>
                                            <Col md={6}>
                                              <Dropdown className="mt-3">
                                                <Dropdown.Toggle variant="outline-success">
                                                  <FontAwesomeIcon icon={faCog} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu variant="dark">
                                                  <Dropdown.Item onClick={() => { configurar(keys, keye); }}>
                                                    <FontAwesomeIcon icon={faScrewdriverWrench} /> Configuración
                                                  </Dropdown.Item>
                                                  <Dropdown.Divider />
                                                  <Dropdown.Item onClick={() => { establecerContenido(keys, keye, elemento.tipo); }}>
                                                    <FontAwesomeIcon icon={faIcons} /> Contenido
                                                  </Dropdown.Item>
                                                  <Dropdown.Divider />
                                                  <Dropdown.Item onClick={() => { cambiarNE(keys, keye); }}>
                                                    <FontAwesomeIcon icon={faEdit} /> Modificar Nombre
                                                  </Dropdown.Item>
                                                  <Dropdown.Divider />
                                                  <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 3, 'Extra Pequeño', keye); }}>
                                                    <FontAwesomeIcon icon={faCube} size="2xs" /> Extra Pequeño
                                                  </Dropdown.Item>
                                                  <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 4, 'Pequeño', keye); }}>
                                                    <FontAwesomeIcon icon={faCube} size="xs" /> Pequeño
                                                  </Dropdown.Item>
                                                  <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 6, 'Mediano', keye); }}>
                                                    <FontAwesomeIcon icon={faCube} size="sm" /> Mediano
                                                  </Dropdown.Item>
                                                  <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 8, 'Grande', keye); }}>
                                                    <FontAwesomeIcon icon={faCube} /> Grande
                                                  </Dropdown.Item>
                                                  <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 9, 'Extra Grande', keye); }}>
                                                    <FontAwesomeIcon icon={faCube} /> Extra Grande
                                                  </Dropdown.Item>
                                                  <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 12, 'Completo', keye); }}>
                                                    <FontAwesomeIcon icon={faCube} size="lg" /> Completo
                                                  </Dropdown.Item>
                                                  <Dropdown.Divider />
                                                </Dropdown.Menu>
                                              </Dropdown>
                                              {/* <Form.Group controlId="selectCarrusel">
                                                <Form.Label>Seleccionar Carrusel de Promociones</Form.Label>
                                                <Form.Control
                                                  as="select"
                                                  value={carruselId}
                                                  onChange={(e) => setCarruselId(e.target.value)}
                                                >
                                                  <option value="">Seleccione un carrusel de promociones</option>
                                                  {carruseles.map(carrusel => (
                                                    <option key={carrusel.id} value={carrusel.id}>
                                                      Carrusel ID: {carrusel.id} - {carrusel.nombre}
                                                    </option>
                                                  ))}
                                                </Form.Control>
                                              </Form.Group> */}

                                              <Form.Group controlId="fechaInicio">
                                                <Form.Label>Fecha de Inicio</Form.Label>
                                                <Form.Control
                                                  type="date"
                                                  value={fechaInicio}
                                                  onChange={(e) => setFechaInicio(e.target.value)}
                                                />
                                              </Form.Group>

                                              <Form.Group controlId="horaInicio">
                                                <Form.Label>Hora de Inicio</Form.Label>
                                                <Form.Control
                                                  type="time"
                                                  value={horaInicio}
                                                  onChange={(e) => setHoraInicio(e.target.value)}
                                                />
                                              </Form.Group>

                                              <Form.Group controlId="fechaFin">
                                                <Form.Label>Fecha de Fin</Form.Label>
                                                <Form.Control
                                                  type="date"
                                                  value={fechaFin}
                                                  onChange={(e) => setFechaFin(e.target.value)}
                                                />
                                              </Form.Group>

                                              <Form.Group controlId="horaFin">
                                                <Form.Label>Hora de Fin</Form.Label>
                                                <Form.Control
                                                  type="time"
                                                  value={horaFin}
                                                  onChange={(e) => setHoraFin(e.target.value)}
                                                />
                                              </Form.Group>

                                              <Form.Group controlId="contadorTamano">
                                                <Form.Label>Tamaño</Form.Label>
                                                <Form.Control
                                                  as="select"
                                                  value={tamano}
                                                  onChange={(e) => setTamano(e.target.value)}
                                                >
                                                  <option value="">Seleccione un tamaño</option>
                                                  <option value="pequeno">Pequeño</option>
                                                  <option value="mediano">Mediano</option>
                                                  <option value="grande">Grande</option>
                                                </Form.Control>
                                              </Form.Group>

                                              <Form.Group controlId="contadorColor">
                                                <Form.Label>Color</Form.Label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                  <Form.Control
                                                    type="color"
                                                    value={color}
                                                    onChange={(e) => setColor(e.target.value)}
                                                    style={{
                                                      width: '50px',
                                                      height: '35px',
                                                      border: 'none',
                                                      cursor: 'pointer',
                                                      marginRight: '10px'
                                                    }}
                                                  />
                                                  <span>{color}</span>
                                                </div>
                                              </Form.Group>
                                              <Form.Group controlId="contadorFondo">
                                                <Form.Label>Cargar Fondo</Form.Label>
                                                <Form.Control
                                                  type="file"
                                                  accept="image/*"
                                                  onChange={handleFondoChange}
                                                  size="sm"
                                                  disabled={nuevoEstado?.estado === 2}
                                                />
                                              </Form.Group>

                                              <div className="d-grid gap-2 mt-3 mb-3">
                                                <Button
                                                  variant="outline-success"
                                                  size="sm"
                                                  onClick={() => document.getElementById('contadorFondo').click()}
                                                  disabled={estadoSubir}
                                                >
                                                  <FontAwesomeIcon icon={faUpload} /> Subir
                                                </Button>
                                              </div>

                                              <Button variant="success" className="mt-3 me-2" onClick={() => handleConfiguracionReloj(estructura)}>
                                                Configurar Reloj
                                              </Button>

                                              <Button
                                                variant="danger"
                                                className="mt-3"
                                                onClick={() => quitarElemento(keys, elemento.id)}
                                              >
                                                <FontAwesomeIcon icon={faTrash} /> Eliminar
                                              </Button>
                                            </Col>

                                            <Col md={6}>
                                              <h5 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}>Vista Previa del Reloj Contador</h5>
                                              <div
                                                style={{
                                                  width: `${dimensionesFondo?.width || 200}px`,
                                                  height: `${dimensionesFondo?.height || 200}px`,
                                                  backgroundColor: color,
                                                  backgroundImage: fondo ? `url(${fondo})` : 'none',
                                                  backgroundSize: 'cover',
                                                  borderRadius: '12px',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                  color: '#000',
                                                  textAlign: 'center',
                                                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                                  padding: '10px',
                                                  position: 'relative',
                                                  overflow: 'hidden',
                                                  fontFamily: "'Arial', sans-serif",
                                                }}
                                              >

                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                  <div style={{ display: 'flex', gap: '8px' }}>
                                                    <span style={{ fontSize: tamano === 'pequeno' ? '18px' : tamano === 'mediano' ? '22px' : '28px', fontWeight: 'bold' }}>
                                                      {tiempoRestante.dias.toString().padStart(2, '0')}
                                                    </span>
                                                    <span style={{ fontSize: tamano === 'pequeno' ? '18px' : tamano === 'mediano' ? '22px' : '28px', fontWeight: 'bold' }}>
                                                      {tiempoRestante.horas.toString().padStart(2, '0')}
                                                    </span>
                                                    <span style={{ fontSize: tamano === 'pequeno' ? '18px' : tamano === 'mediano' ? '22px' : '28px', fontWeight: 'bold' }}>
                                                      {tiempoRestante.minutos.toString().padStart(2, '0')}
                                                    </span>
                                                    <span style={{ fontSize: tamano === 'pequeno' ? '18px' : tamano === 'mediano' ? '22px' : '28px', fontWeight: 'bold' }}>
                                                      {tiempoRestante.segundos.toString().padStart(2, '0')}
                                                    </span>
                                                  </div>
                                                  <div style={{ display: 'flex', gap: '8px', marginTop: '5px', color: '#333', fontSize: tamano === 'pequeno' ? '12px' : tamano === 'mediano' ? '14px' : '16px' }}>
                                                    <span>días</span>
                                                    <span>horas</span>
                                                    <span>mins.</span>
                                                    <span>segs.</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Row>
                                      </Card.Body>
                                    </Card.Body>
                                  </Card>
                                ) : elemento.tipo === 'CarruselPromociones' ? (
                                  <Card bg="light" border="success" text="success">
                                    <Card.Body>
                                      <Card.Title>
                                        <h6>
                                          <FontAwesomeIcon icon={faToolbox} />
                                          <u>Elemento {keye + 1}</u>: {elemento.nombre}{' '}
                                          <Badge bg="success">{elemento.tipo}</Badge>
                                        </h6>

                                      </Card.Title>
                                      <Card.Body>
                                        <Row>
                                          <Col md={6}>
                                            <Dropdown className="mt-3">
                                              <Dropdown.Toggle variant="outline-success">
                                                <FontAwesomeIcon icon={faCog} />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu variant="dark">
                                                <Dropdown.Item onClick={() => { configurar(keys, keye); }}>
                                                  <FontAwesomeIcon icon={faScrewdriverWrench} /> Configuración
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item onClick={() => { establecerContenido(keys, keye, elemento.tipo); }}>
                                                  <FontAwesomeIcon icon={faIcons} /> Contenido
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item onClick={() => { cambiarNE(keys, keye); }}>
                                                  <FontAwesomeIcon icon={faEdit} /> Modificar Nombre
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 3, 'Extra Pequeño', keye); }}>
                                                  <FontAwesomeIcon icon={faCube} size="2xs" /> Extra Pequeño
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 4, 'Pequeño', keye); }}>
                                                  <FontAwesomeIcon icon={faCube} size="xs" /> Pequeño
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 6, 'Mediano', keye); }}>
                                                  <FontAwesomeIcon icon={faCube} size="sm" /> Mediano
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 8, 'Grande', keye); }}>
                                                  <FontAwesomeIcon icon={faCube} /> Grande
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 9, 'Extra Grande', keye); }}>
                                                  <FontAwesomeIcon icon={faCube} /> Extra Grande
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 12, 'Completo', keye); }}>
                                                  <FontAwesomeIcon icon={faCube} size="lg" /> Completo
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                              </Dropdown.Menu>
                                            </Dropdown>
                                            {/* Checkbox para alternar entre promociones y categorías */}
                                            <Form.Group controlId="toggleCategoria">
                                              <Form.Check
                                                type="checkbox"
                                                label="Seleccionar Categoría en lugar de Promoción"
                                                checked={esCategoria}
                                                onChange={(e) => setEsCategoria(e.target.checked)}
                                                className="mt-3 me-2"
                                              />
                                            </Form.Group>
                                            <Form.Group controlId="selectPromocion">
                                              <Form.Label>
                                                {esCategoria ? 'Seleccione una categoría' : 'Seleccione una promoción'}
                                              </Form.Label>
                                              <Form.Control
                                                as="select"
                                                value={esCategoria ? categoriaId : promocionId}
                                                onChange={(e) => esCategoria ? setCategoriaId(e.target.value) : setPromocionId(e.target.value)}
                                              >
                                                <option value="">
                                                  {esCategoria ? 'Seleccione una categoría' : 'Seleccione una promoción'}
                                                </option>
                                                {(esCategoria ? categorias : promociones).map((opcion) => (
                                                  <option key={opcion.ruta} value={opcion.ruta}>
                                                    {opcion.nombre}
                                                  </option>
                                                ))}
                                              </Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId="cantidadProductos">
                                              <Form.Label>Cantidad de Productos a Visualizar</Form.Label>
                                              <Form.Control
                                                type="number"
                                                value={cantidadProductos}
                                                onChange={(e) => setCantidadProductos(e.target.value)}
                                                min="1"
                                                max="20"
                                              />
                                            </Form.Group>
                                            <Form.Group controlId="tamanoCarrusel">
                                              <Form.Label>Tamaño del Carrusel</Form.Label>
                                              <Form.Control
                                                as="select"
                                                value={tamanoCarrusel}
                                                onChange={(e) => setTamanoCarrusel(e.target.value)}
                                              >
                                                <option value="">Seleccione un tamaño</option>
                                                <option value="pequeno">Pequeño</option>
                                                <option value="mediano">Mediano</option>
                                                <option value="grande">Grande</option>
                                              </Form.Control>
                                            </Form.Group>
                                            <Button variant="success" className="mt-3 me-2" onClick={() => handleConfiguracionCarrusel(estructura)}>
                                              Configurar Carrusel
                                            </Button>
                                            <Button variant="danger" className="mt-3" onClick={() => quitarElemento(keys, elemento.id)}>
                                              <FontAwesomeIcon icon={faTrash} /> Eliminar
                                            </Button>
                                          </Col>
                                          <Col md={6}>
                                            <h5 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}>
                                              Vista Previa del Carrusel
                                            </h5>
                                            <div
                                              style={{
                                                width: '100%',
                                                height: '200px',
                                                overflow: 'hidden',
                                                borderRadius: '12px',
                                                border: '1px solid #28a745',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: '#f5f5f5',
                                                boxSizing: 'border-box',
                                                padding: '10px'
                                              }}
                                            >
                                              {promocionId || esCategoria ? (
                                                <Carousel style={{ width: '100%', height: '100%' }}>
                                                  {productos.slice(0, cantidadProductos).map(producto => (
                                                    <Carousel.Item key={producto.ruta}>
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          height: '100%',
                                                          width: '100%'
                                                        }}
                                                      >
                                                        <img
                                                          src={
                                                            "https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/store/" +
                                                            producto.imagenes[0]?.url?.[600]
                                                          }
                                                          alt={producto.nombre}
                                                          style={{
                                                            maxWidth: '50%',
                                                            maxHeight: '50%',
                                                            width: 'auto',
                                                            height: 'auto',
                                                            objectFit: 'contain'
                                                          }}
                                                        />
                                                      </div>
                                                    </Carousel.Item>
                                                  ))}
                                                </Carousel>
                                              ) : (
                                                <p>{`Seleccione una ${esCategoria ? 'categoría' : 'promoción'} para ver la vista previa`}</p>
                                              )}
                                            </div>
                                          </Col>

                                        </Row>
                                      </Card.Body>
                                    </Card.Body>
                                  </Card>
                                ) : (
                                  <Card bg="light" border="success" text="success">
                                    <Card.Body>
                                      <Card.Title>
                                        <h6>
                                          <FontAwesomeIcon icon={faToolbox} />
                                          <u>Elemento {keye + 1}</u>: {elemento.nombre}{' '}
                                          <Badge bg="success">{elemento.tipo}</Badge>
                                        </h6>
                                        {seccion.descuento !== undefined ? (
                                          ''
                                        ) : (
                                          <Dropdown>
                                            <Dropdown.Toggle variant='outline-success'>
                                              <FontAwesomeIcon icon={faCog} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu variant='dark'>
                                              <Dropdown.Item onClick={() => { configurar(keys, keye); }}>
                                                <FontAwesomeIcon icon={faScrewdriverWrench} /> Configuración
                                              </Dropdown.Item>
                                              <Dropdown.Divider />
                                              <Dropdown.Item onClick={() => { establecerContenido(keys, keye, elemento.tipo); }}>
                                                <FontAwesomeIcon icon={faIcons} /> Contenido
                                              </Dropdown.Item>
                                              <Dropdown.Divider />
                                              <Dropdown.Item onClick={() => { cambiarNE(keys, keye); }}>
                                                <FontAwesomeIcon icon={faEdit} /> Modificar Nombre
                                              </Dropdown.Item>
                                              <Dropdown.Divider />
                                              <Dropdown.Item onClick={() => { indiceElemento(keys, elemento.tamano, 3, 'Extra Pequeño', keye); }}>
                                                <FontAwesomeIcon icon={faCube} size={'2xs'} /> Extra Pequeño
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  indiceElemento(keys, elemento.tamano, 3, 'Extra Pequeño', keye);
                                                }}
                                              >
                                                <FontAwesomeIcon icon={faCube} size={'2xs'} /> Extra Pequeño
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  indiceElemento(keys, elemento.tamano, 4, 'Pequeño', keye);
                                                }}
                                              >
                                                <FontAwesomeIcon icon={faCube} size={'xs'} />
                                                Pequeño
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  indiceElemento(keys, elemento.tamano, 6, 'Mediano', keye);
                                                }}
                                              >
                                                <FontAwesomeIcon icon={faCube} size={'sm'} />
                                                Mediano
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  indiceElemento(keys, elemento.tamano, 8, 'Grande', keye);
                                                }}
                                              >
                                                <FontAwesomeIcon icon={faCube} />
                                                Grande
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  indiceElemento(keys, elemento.tamano, 9, 'Extra Grande', keye);
                                                }}
                                              >
                                                <FontAwesomeIcon icon={faCube} />
                                                Extra Grande
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  indiceElemento(keys, elemento.tamano, 12, 'Completo', keye);
                                                }}
                                              >
                                                <FontAwesomeIcon icon={faCube} size={'lg'} />
                                                Completo
                                              </Dropdown.Item>
                                              <Dropdown.Divider />

                                              <Dropdown.Divider />
                                              <Dropdown.Item onClick={() => quitarElemento(keys, elemento.id)}>
                                                <FontAwesomeIcon icon={faTrash} /> Eliminar
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        )}
                                      </Card.Title>
                                      <Card.Body>
                                        <Row>
                                          <Col md={6}>
                                            {seccion.descuento !== undefined ? (
                                              ''
                                            ) : (
                                              <>
                                                {elemento?.filtro?.origen === '' ? null : (
                                                  <Card.Text>
                                                    <u>Origen:</u> {elemento?.filtro?.origen}
                                                  </Card.Text>
                                                )}
                                                {elemento?.filtro?.tipo === '' ? null : (
                                                  <Card.Text>
                                                    <u>Tipo:</u> {elemento?.filtro?.tipo}
                                                  </Card.Text>
                                                )}
                                                {elemento?.filtro?.valor === '' ? null : (
                                                  <Card.Text>
                                                    <u>Valor:</u> {elemento?.filtro?.valor}
                                                  </Card.Text>
                                                )}
                                              </>
                                            )}
                                            <Card.Text>
                                              <u>Cantidad:</u> {elemento?.contenidos?.length}/{elemento?.filtro?.cantidad}{' '}
                                              {parseInt(elemento?.contenidos?.length) < parseInt(elemento?.filtro?.cantidad) ? (
                                                <Badge bg='danger'>
                                                  <FontAwesomeIcon icon={faTimes} />
                                                </Badge>
                                              ) : (
                                                <Badge bg='success'>
                                                  <FontAwesomeIcon icon={faCheck} />
                                                </Badge>
                                              )}
                                            </Card.Text>
                                          </Col>
                                          <Col md={6}>
                                            <Form.Check
                                              type='switch'
                                              id={`nombre${keys}${keye}`}
                                              label='Nombre'
                                              defaultChecked={estructura[keys].elementos[keye].descriptivo?.nombre}
                                              onChange={() => descriptivo(1, keys, keye)}
                                            />
                                            <Form.Check
                                              type='switch'
                                              id={`contado${keys}${keye}`}
                                              label='Contado'
                                              defaultChecked={estructura[keys].elementos[keye].descriptivo?.contado}
                                              onChange={() => descriptivo(2, keys, keye)}
                                            />
                                            <Form.Check
                                              type='switch'
                                              id={`cuota${keys}${keye}`}
                                              label='Cuota'
                                              defaultChecked={estructura[keys].elementos[keye].descriptivo?.cuota}
                                              onChange={() => descriptivo(3, keys, keye)}
                                            />
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Card.Body>
                                  </Card>
                                )}
                              </Col>


                            ))}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
                {!!elegido.nombre && <ScrollCategorias categorias={categorias} elegido={elegido} />}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <Offcanvas
        show={izquierdo}
        onHide={cerrarIzquierdo}
        backdrop='static'
        placement='start'
        className='bg-dark text-white'
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <FontAwesomeIcon icon={faFileCode} />
            Plantillas
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Dropdown>
            <Dropdown.Toggle variant='outline-primary'>
              <FontAwesomeIcon icon={faCogs} />
              Opciones
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {obtenerPermiso(cuenta.interfaces, Interfaz, 'crear') ? (
                <>
                  <Dropdown.Item onClick={nuevo}>
                    <FontAwesomeIcon icon={faPlus} />
                    Nuevo
                  </Dropdown.Item>
                </>
              ) : (
                ''
              )}
              <Dropdown.Item onClick={actualizar}>
                <FontAwesomeIcon icon={faRefresh} />
                Actualizar
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {datos?.length > 0 ? (
            <ListGroup as='ol' numbered className='mt-3 mb-3'>
              {datos.map((dato, key) => (
                <ListGroup.Item
                  as='li'
                  action
                  active={key === seleccionPlantilla}
                  key={key}
                  onClick={() => {
                    elegir(dato.codigo);
                    setSeleccionPlantilla(key);
                  }}
                >
                  {dato.nombre}{' '}
                  {dato.estado === 1 ? (
                    <Badge bg='success'>
                      <FontAwesomeIcon icon={faCheck} />
                      Principal
                    </Badge>
                  ) : (
                    ''
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p>No hay datos disponibles</p>
          )}

        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={inferior}
        onHide={cerrarInferior}
        backdrop='static'
        placement='bottom'
        className='bg-dark text-white'
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <FontAwesomeIcon icon={faToolbox} />
            Elementos
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('Carta Simple');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Carta Simple
          </Button>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('Carta Multiple');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Carta Multiple
          </Button>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('Carrusel Regular');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Carrusel Regular
          </Button>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('CarruselPromociones');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Carrusel de Promociones
          </Button>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('RelojContador');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Reloj Contador
          </Button>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('Banner');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Banner
          </Button>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('Videos');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Videos
          </Button>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={derecho} onHide={cerrarDerecho} backdrop='static' placement='end' className='bg-dark text-white'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {/* checkpoint */}
            <FontAwesomeIcon icon={faIcons} />
            Contenido
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col md={12}>
              <FloatingLabel controlId='cbOrigen' label='Origen' className='text-dark mb-2'>
                <Form.Select aria-label='Origen' disabled={false}>
                  {origenContenido.map((oc, llave) => (
                    <option value={oc.toLowerCase()} key={llave}>
                      {oc}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel controlId='cbTipo' label='Tipo' className='text-dark mb-2'>
                <Form.Select aria-label='Tipo' disabled={false} onChange={cargarContenidos}>
                  {tipoContenido.map((tc, llave) => (
                    <option value={tc.toLowerCase()} key={llave}>
                      {tc}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel controlId='cbValor' label='Valor' className='text-dark mb-2'>
                <Form.Select aria-label='Valor' disabled={false}>
                  {valorContenido.map((vc, llave) => (
                    <option value={vc.ruta === undefined ? vc : vc.ruta} key={llave}>
                      {vc.nombre === undefined ? vc : vc.nombre}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel controlId='cbCantidad' label='Cantidad' className='text-dark mb-2'>
                <Form.Select aria-label='Cantidad' disabled={false}>
                  {cantidadContenido.map((cc, llave) => (
                    <option value={cc.toLowerCase()} key={llave}>
                      {cc}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              <div className='d-grid gap-2'>
                <Button variant='outline-primary' size='lg' onClick={guardarContenido}>
                  <FontAwesomeIcon icon={faCheck} />
                  Guardar
                </Button>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={modalPagina} onHide={cerrarModalPagina} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faFileCode} />
            Página
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId='txtNombre' label='Nombre' onKeyUp={eventoEnterNP}>
            <Form.Control type='text' placeholder='Nombre' defaultValue={''} />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={cerrarPagina} size='lg'>
            <FontAwesomeIcon icon={faTimes} />
            Cerrar
          </Button>
          <Button variant='outline-primary' onClick={guardarPagina} size='lg'>
            <FontAwesomeIcon icon={faCheck} />
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalNE} onHide={cerrarModalNE} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faToolbox} />
            Elemento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId='txtNombreE' label='Nombre' onKeyUp={eventoEnterNE}>
            <Form.Control type='text' placeholder='Nombre' />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={cerrarNE} size='lg'>
            <FontAwesomeIcon icon={faTimes} />
            Cerrar
          </Button>
          <Button variant='outline-primary' onClick={guardarNE} size='lg'>
            <FontAwesomeIcon icon={faCheck} />
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalConfig} onHide={cerrarModalConfig} backdrop='static' keyboard={false} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faScrewdriverWrench} />
            Configuración
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Card border='dark' className='mt-2 mb-2'>
                <Card.Body>
                  <Card.Title>
                    <FontAwesomeIcon icon={faToolbox} />
                    Elemento
                  </Card.Title>
                  <Row>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Titulo</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Tamaño</InputGroup.Text>
                            <Form.Select id='tituloTamanoElemento' defaultValue={configuracion.elemento.titulo.tamano}>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                              <option value='16'>16</option>
                              <option value='17'>17</option>
                              <option value='18'>18</option>
                              <option value='19'>19</option>
                              <option value='20'>20</option>
                              <option value='21'>21</option>
                              <option value='22'>22</option>
                              <option value='23'>23</option>
                              <option value='24'>24</option>
                              <option value='25'>25</option>
                              <option value='26'>26</option>
                              <option value='27'>27</option>
                              <option value='28'>28</option>
                              <option value='29'>29</option>
                              <option value='30'>30</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('tituloTamanoElemento').value =
                                  configDefecto.elemento.titulo.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='tituloColorElemento'
                              defaultValue={
                                configuracion.elemento.titulo.color === ''
                                  ? '#EC650A'
                                  : configuracion.elemento.titulo.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('tituloColorElemento').value =
                                  configDefecto.elemento.titulo.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Fondo</center>
                          </Card.Title>
                          <InputGroup size='sm'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='fondoColorElemento'
                              defaultValue={
                                configuracion.elemento.fondo.color === ''
                                  ? '#EC650A'
                                  : configuracion.elemento.fondo.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('fondoColorElemento').value =
                                  configDefecto.elemento.fondo.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Borde</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Grosor</InputGroup.Text>
                            <Form.Select id='bordeTamanoElemento' defaultValue={configuracion.elemento.borde.tamano}>
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeTamanoElemento').value =
                                  configDefecto.elemento.borde.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Redondeo</InputGroup.Text>
                            <Form.Select
                              id='bordeRedondeoElemento'
                              defaultValue={configuracion.elemento.borde.redondeo}
                            >
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeRedondeoElemento').value =
                                  configDefecto.elemento.borde.redondeo;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='bordeColorElemento'
                              defaultValue={
                                configuracion.elemento.borde.color === ''
                                  ? '#EC650A'
                                  : configuracion.elemento.borde.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeColorElemento').value =
                                  configDefecto.elemento.fondo.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Efectos</center>
                          </Card.Title>
                          <Row>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Comportamiento</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionComportamientoElemento'
                                          defaultValue={configuracion.elemento.efecto.comportamiento.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionComportamiento.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionComportamientoElemento').value =
                                              configDefecto.elemento.efecto.comportamiento.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionComportamientoElemento'
                                          defaultValue={configuracion.elemento.efecto.comportamiento.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionComportamientoElemento').value =
                                              configDefecto.elemento.efecto.comportamiento.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionComportamientoElemento'
                                          defaultValue={configuracion.elemento.efecto.comportamiento.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionComportamientoElemento').value =
                                              configDefecto.elemento.efecto.comportamiento.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoComportamientoElemento'
                                          defaultValue={configuracion.elemento.efecto.comportamiento.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoComportamientoElemento').value =
                                              configDefecto.elemento.efecto.comportamiento.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Entrada</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionEntradaElemento'
                                          defaultValue={configuracion.elemento.efecto.entrada.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionEntrada.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionEntradaElemento').value =
                                              configDefecto.elemento.efecto.entrada.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionEntradaElemento'
                                          defaultValue={configuracion.elemento.efecto.entrada.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionEntradaElemento').value =
                                              configDefecto.elemento.efecto.entrada.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionEntradaElemento'
                                          defaultValue={configuracion.elemento.efecto.entrada.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionEntradaElemento').value =
                                              configDefecto.elemento.efecto.entrada.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoEntradaElemento'
                                          defaultValue={configuracion.elemento.efecto.entrada.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoEntradaElemento').value =
                                              configDefecto.elemento.efecto.entrada.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Salida</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionSalidaElemento'
                                          defaultValue={configuracion.elemento.efecto.salida.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionSalida.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionSalidaElemento').value =
                                              configDefecto.elemento.efecto.salida.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionSalidaElemento'
                                          defaultValue={configuracion.elemento.efecto.salida.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionSalidaElemento').value =
                                              configDefecto.elemento.efecto.salida.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionSalidaElemento'
                                          defaultValue={configuracion.elemento.efecto.salida.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionSalidaElemento').value =
                                              configDefecto.elemento.efecto.salida.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoSalidaElemento'
                                          defaultValue={configuracion.elemento.efecto.salida.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoSalidaElemento').value =
                                              configDefecto.elemento.efecto.salida.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={12}>
              <Card border='dark' className='mt-2 mb-2'>
                <Card.Body>
                  <Card.Title>
                    <FontAwesomeIcon icon={faIcons} />
                    Contenido
                  </Card.Title>
                  <Row>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Fondo</center>
                          </Card.Title>
                          <InputGroup size='sm'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='fondoColorContenido'
                              defaultValue={
                                configuracion.contenido.fondo.color === ''
                                  ? '#EC650A'
                                  : configuracion.contenido.fondo.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('fondoColorContenido').value =
                                  configDefecto.contenido.fondo.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Borde</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Grosor</InputGroup.Text>
                            <Form.Select id='bordeTamanoContenido' defaultValue={configuracion.contenido.borde.tamano}>
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeTamanoContenido').value =
                                  configDefecto.contenido.borde.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Redondeo</InputGroup.Text>
                            <Form.Select
                              id='bordeRedondeoContenido'
                              defaultValue={configuracion.contenido.borde.redondeo}
                            >
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeRedondeoContenido').value =
                                  configDefecto.contenido.borde.redondeo;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='bordeColorContenido'
                              defaultValue={
                                configuracion.contenido.borde.color === ''
                                  ? '#EC650A'
                                  : configuracion.contenido.borde.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeColorContenido').value =
                                  configDefecto.contenido.borde.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Texto</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Tamaño</InputGroup.Text>
                            <Form.Select id='textoTamanoContenido' defaultValue={configuracion.contenido.texto.tamano}>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                              <option value='16'>16</option>
                              <option value='17'>17</option>
                              <option value='18'>18</option>
                              <option value='19'>19</option>
                              <option value='20'>20</option>
                              <option value='21'>21</option>
                              <option value='22'>22</option>
                              <option value='23'>23</option>
                              <option value='24'>24</option>
                              <option value='25'>25</option>
                              <option value='26'>26</option>
                              <option value='27'>27</option>
                              <option value='28'>28</option>
                              <option value='29'>29</option>
                              <option value='30'>30</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('textoTamanoContenido').value =
                                  configDefecto.contenido.texto.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='textoColorContenido'
                              defaultValue={
                                configuracion.contenido.texto.color === ''
                                  ? '#EC650A'
                                  : configuracion.contenido.texto.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('textoColorContenido').value =
                                  configDefecto.contenido.texto.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Icono</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Tamaño</InputGroup.Text>
                            <Form.Select id='iconoTamanoContenido' defaultValue={configuracion.contenido.icono.tamano}>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                              <option value='16'>16</option>
                              <option value='17'>17</option>
                              <option value='18'>18</option>
                              <option value='19'>19</option>
                              <option value='20'>20</option>
                              <option value='21'>21</option>
                              <option value='22'>22</option>
                              <option value='23'>23</option>
                              <option value='24'>24</option>
                              <option value='25'>25</option>
                              <option value='26'>26</option>
                              <option value='27'>27</option>
                              <option value='28'>28</option>
                              <option value='29'>29</option>
                              <option value='30'>30</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('iconoTamanoContenido').value =
                                  configDefecto.contenido.icono.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='iconoColorContenido'
                              defaultValue={
                                configuracion.contenido.icono.color === ''
                                  ? '#EC650A'
                                  : configuracion.contenido.icono.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('iconoColorContenido').value =
                                  configDefecto.contenido.icono.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Sello</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Tamaño</InputGroup.Text>
                            <Form.Select id='selloTamanoContenido' defaultValue={configuracion.contenido.sello.tamano}>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                              <option value='16'>16</option>
                              <option value='17'>17</option>
                              <option value='18'>18</option>
                              <option value='19'>19</option>
                              <option value='20'>20</option>
                              <option value='21'>21</option>
                              <option value='22'>22</option>
                              <option value='23'>23</option>
                              <option value='24'>24</option>
                              <option value='25'>25</option>
                              <option value='26'>26</option>
                              <option value='27'>27</option>
                              <option value='28'>28</option>
                              <option value='29'>29</option>
                              <option value='30'>30</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('selloTamanoContenido').value =
                                  configDefecto.contenido.sello.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Efectos</center>
                          </Card.Title>
                          <Row>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Comportamiento</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionComportamientoContenido'
                                          defaultValue={configuracion.contenido.efecto.comportamiento.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionComportamiento.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionComportamientoContenido').value =
                                              configDefecto.contenido.efecto.comportamiento.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionComportamientoContenido'
                                          defaultValue={configuracion.contenido.efecto.comportamiento.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionComportamientoContenido').value =
                                              configDefecto.contenido.efecto.comportamiento.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionComportamientoContenido'
                                          defaultValue={configuracion.contenido.efecto.comportamiento.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionComportamientoContenido').value =
                                              configDefecto.contenido.efecto.comportamiento.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoComportamientoContenido'
                                          defaultValue={configuracion.contenido.efecto.comportamiento.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoComportamientoContenido').value =
                                              configDefecto.contenido.efecto.comportamiento.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Entrada</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionEntradaContenido'
                                          defaultValue={configuracion.contenido.efecto.entrada.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionEntrada.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionEntradaContenido').value =
                                              configDefecto.contenido.efecto.entrada.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionEntradaContenido'
                                          defaultValue={configuracion.contenido.efecto.entrada.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionEntradaContenido').value =
                                              configDefecto.contenido.efecto.entrada.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionEntradaContenido'
                                          defaultValue={configuracion.contenido.efecto.entrada.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionEntradaContenido').value =
                                              configDefecto.contenido.efecto.entrada.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoEntradaContenido'
                                          defaultValue={configuracion.contenido.efecto.entrada.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoEntradaContenido').value =
                                              configDefecto.contenido.efecto.entrada.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Salida</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionSalidaContenido'
                                          defaultValue={configuracion.contenido.efecto.salida.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionSalida.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionSalidaContenido').value =
                                              configDefecto.contenido.efecto.salida.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionSalidaContenido'
                                          defaultValue={configuracion.contenido.efecto.salida.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionSalidaContenido').value =
                                              configDefecto.contenido.efecto.salida.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionSalidaContenido'
                                          defaultValue={configuracion.contenido.efecto.salida.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionSalidaContenido').value =
                                              configDefecto.contenido.efecto.salida.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoSalidaContenido'
                                          defaultValue={configuracion.contenido.efecto.salida.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoSalidaContenido').value =
                                              configDefecto.contenido.efecto.salida.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className='d-grid gap-2'>
            <Button variant='outline-primary' onClick={guardarConfig} size='lg'>
              <FontAwesomeIcon icon={faCheck} />
              Guardar
            </Button>
            <Button variant='outline-secondary' onClick={cerrarConfig} size='lg'>
              <FontAwesomeIcon icon={faTimes} />
              Cerrar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
