import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { version } from './utils/constantes';

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log('Admin Ecommerce Central Shop V - ',version)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);