import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { obtenerInterfaz, reportar, separarDigitos } from '../recursos/funcion';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCubes, faEye, faEyeSlash, faFilter, faArrowUpRightFromSquare, faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { InputGroup, Form, Pagination, Button, FloatingLabel, Offcanvas, Table } from 'react-bootstrap';
import Axios from 'axios';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Swal from 'sweetalert2';

export default function Productos() {
    const cuenta = useUsuarioContexto();
    const Interfaz = 'producto';

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [pagina, setPagina] = useState(1);
    const [intervalo, setIntervalo] = useState(10);
    const [totalPaginas, setTotalPaginas] = useState(20); // Mostrar hasta 20 páginas
    const [datos, setDatos] = useState([]);
    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState({ inicio: 0, fin: 0 });
    const [carga, setCarga] = useState(false);
    const [codigo, setCodigo] = useState(''); // Cambiado a cadena vacía
    const [nombre, setNombre] = useState('');
    const [marcas, setMarcas] = useState([]);
    const [marca, setMarca] = useState("tm");
    const [categorias, setCategorias] = useState([]);
    const [categoria, setCategoria] = useState("tc");
    const [subcategorias, setSubcategorias] = useState([]);
    const [subcategoria, setSubcategoria] = useState("ts"); // Estado para subcategoría
    const [filtros, setFiltros] = useState(false);
    const [orden, setOrden] = useState("nombre");
    const [ordenModificado, setOrdenModificado] = useState({}); // Estado para guardar el orden modificado

    const cerrarFiltros = () => setFiltros(false);
    const mostrarFiltros = () => setFiltros(true);

    // Cargar las opciones de marcas, categorías y subcategorías
    useEffect(() => {
        cargarSeleccion();
    }, []);

    useEffect(() => {
        setPagina(parseInt(parametro.pagina));
        setIntervalo(parseInt(parametro.intervalo));
        setMarca(parametro.marca === undefined ? 'tm' : parametro.marca);
        setCategoria(parametro.categoria === undefined ? 'tc' : parametro.categoria);
    }, [locacion]);

    // Actualizar los datos de la tabla cuando cambian los filtros o la paginación
    useEffect(() => {
        recuperarProductosConFiltros();
    }, [pagina, intervalo, marca, categoria, subcategoria]);

    // Función para cargar marcas, categorías y subcategorías desde la API
    async function cargarSeleccion() {
        try {
            let respuesta = await Axios.get(urlEcommerceBackend + '/consultas/marca');
            const marcasFiltradas = respuesta.data.arrayJson.filter(marca => marca.nombre && marca.nombre.trim() !== '');
            setMarcas(marcasFiltradas);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "productos.jsx", "cargarSeleccion", `${error.toString()}`, { error }, 1);
        }

        try {
            let respuesta = await Axios.get(urlEcommerceBackend + '/categorias');
            setCategorias(respuesta.data.arrayJson);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "productos.jsx", "cargarSeleccion", `${error.toString()}`, { error }, 1);
        }
    }

    async function recuperarProductosConFiltros() {
        setCarga(true);
        try {
            // Construir los parámetros de consulta
            const params = new URLSearchParams();
            params.append('pagina', pagina);
            params.append('intervalo', intervalo);
            params.append('orden', orden);

            // Verificación de parámetros antes de enviarlos al backend
            console.log("Parámetros para la recuperación:", { pagina, intervalo, orden, codigo, nombre, marca, categoria, subcategoria });

            if (codigo !== '') {
                params.append('codigo', codigo);
            }

            if (nombre !== '') {
                params.append('nombre', nombre);
            }

            // Aplicar filtros de marca y categoría correctamente
            if (marca !== 'tm' && marca !== 'sm') {
                params.append('marca', marca);
            }

            if (categoria !== 'tc' && categoria !== 'sc') {
                params.append('categoria', categoria);
            }

            // Generar la URL con los filtros

            if (subcategoria !== 'ts' && subcategoria !== 'ss') {
                params.append('subcategoria', subcategoria);
            }

            const url = `${urlEcommerceBackend}/productos?${params.toString()}`;
            let respuesta = await Axios.get(url, {
                headers: { authorization: `Bearer ${cuenta.token}` }
            });

            console.log("Respuesta del backend:", respuesta.data);

            let productosRecuperados = respuesta.data.arrayJson;

            // Aplicar el orden modificado si existe
            if (ordenModificado[categoria]) {
                productosRecuperados = aplicarOrdenModificado(productosRecuperados, ordenModificado[categoria]);
                console.log("Productos con orden modificado:", productosRecuperados);
            }

            setDatos(productosRecuperados);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setTotalPaginas(respuesta.data.objetoJson.paginado.fin);
            setCarga(false);
        } catch (error) {
            setCarga(false);
            console.error("Error recuperando productos con filtros:", error);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "productos.jsx", "recuperarProductosConFiltros", `${error.toString()}`, { error }, 1);
        }
    }

    // Aplicar un orden modificado en los productos si corresponde
    const aplicarOrdenModificado = (productos, ordenGuardado) => {
        return productos.sort((a, b) => ordenGuardado.indexOf(a.codigo) - ordenGuardado.indexOf(b.codigo));
    };

    async function buscarCodigo() {
        if (codigo === '') {
            recuperarProductosConFiltros();
            return;
        } 
        let respuesta = [];
        setCarga(true);
        try {
            respuesta = await Axios.get(`${urlEcommerceBackend}/productos/${codigo}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setCarga(false);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "productos.jsx", "buscarCodigo", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function buscarNombre() {
        if (nombre === '') {
            recuperarProductosConFiltros();
            return;
        }
        let respuesta = [];
        setCarga(true);
        try {
            respuesta = await Axios.get(`${urlEcommerceBackend}/productos?nombre=${nombre}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setCarga(false);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "productos.jsx", "buscarNombre", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    const establecerIntervalo = (e) => {
        setIntervalo(parseInt(e.target.value));
        setPagina(1);
        recuperarProductosConFiltros(); // Recargar al cambiar el intervalo
    };

    const establecerMarca = (e) => {
        setMarca(e.target.value);
        setPagina(1); // Reiniciar a la primera página cuando se cambia el filtro
    };

    useEffect(() => {
        recuperarProductosConFiltros(); // Recargar al cambiar la marca, categoría o subcategoría
    }, [marca, categoria, subcategoria]);

    const establecerCategoria = async (e) => {
        const nuevaCategoria = e.target.value;
        setCategoria(nuevaCategoria);
        setSubcategoria(''); 
        setPagina(1); 

        try {
            const { data } = await Axios.get(`${urlEcommerceBackend}/categorias`);
            const newCategorias = data.arrayJson.filter((categoria) => !!categoria.nombre && !!categoria.ruta);
            const subcategoriasAsociadas = [];
            newCategorias.forEach((categoria) => {
                if (categoria.nombre === nuevaCategoria) {
                    categoria.subcategoria.forEach((sub) => {
                        subcategoriasAsociadas.push(sub); 
                    });
                }
            });
            setSubcategorias(subcategoriasAsociadas);
        } catch (error) {
            console.error("Error al cargar subcategorías:", error);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "productos.jsx", "establecerCategoria", `${error.toString()}`, { error }, 1);
        }
    };

    const establecerSubcategoria = (e) => {
        setSubcategoria(e.target.value);
        setPagina(1); // Reiniciar a la primera página cuando se cambia el filtro
    };

    const eventoEnter = (event) => {
        if (event.key === 'Enter') {
            if (codigo !== '') {
                buscarCodigo(); 
            } else if (nombre !== '') {
                buscarNombre(); 
            } else {
                recuperarProductosConFiltros(); 
            }
        }
    };
    

    const dirigir = (paginanueva) => {
        navegar(`/productos/${paginanueva}/${intervalo}/${marca}/${categoria}`);
    };

    const dirigirProducto = (codigo) => {
        navegar(`/producto/${codigo}`);
    };

    const confirmarDistribucion = async () => {
        try {
            // Validar que la categoría no sea 'tc' (todas las categorías) o 'undefined'
            if (!categoria || categoria === 'tc') {
                Swal.fire({
                    title: 'Categoría no válida',
                    text: 'Por favor, selecciona una categoría antes de confirmar la distribución.',
                    icon: 'warning',
                    confirmButtonText: 'Aceptar'
                });
                return;
            }

            // Validar que la subcategoría no sea 'tc' (todas las categorías) o 'undefined'
            if (!subcategoria || subcategoria === 'ts') {
                Swal.fire({
                    title: 'Sub-Categoría no válida',
                    text: 'Por favor, selecciona una subcategoría antes de confirmar la distribución.',
                    icon: 'warning',
                    confirmButtonText: 'Aceptar'
                });
                return;
            }

            await Axios.post(`${urlEcommerceBackend}/productos/distribucion`, {
                productos: datos,
                categoria_producto: { nombre: categoria },// Enviar la categoría seleccionada
                subcategoria_producto: { nombre: subcategoria } // Enviar la subcategoría seleccionada
            }, {
                headers: { authorization: `Bearer ${cuenta.token}` }
            });

            // Verificar si hay un nuevo orden modificado
            if (Object.keys(ordenModificado).length > 0) {
                Swal.fire({
                    title: 'Orden modificado',
                    text: 'El orden de los productos ha sido modificado.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                });
            } else {
                Swal.fire({
                    title: 'Sin cambios',
                    text: 'No se ha modificado el orden de los productos.',
                    icon: 'info',
                    confirmButtonText: 'Aceptar'
                });
            }
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "productos.jsx", "confirmarDistribucion", `${error.toString()}`, { error }, 1);
        }
    };


    const moveRow = (dragIndex, hoverIndex) => {
        const updatedDatos = [...datos];
        const [draggedItem] = updatedDatos.splice(dragIndex, 1);
        updatedDatos.splice(hoverIndex, 0, draggedItem);
        setDatos(updatedDatos);

        const nuevoOrden = updatedDatos.map((producto) => producto.codigo);
        setOrdenModificado((prevState) => ({
            ...prevState,
            [categoria]: nuevoOrden
        }));
    };

    const DraggableRow = ({ item, index }) => {
        const [, dragRef] = useDrag({
            type: "row",
            item: { index }
        });

        const [, dropRef] = useDrop({
            accept: "row",
            hover: (draggedItem) => {
                if (draggedItem.index !== index) {
                    moveRow(draggedItem.index, index);
                    draggedItem.index = index;
                }
            }
        });

        return (
            <tr ref={(node) => dragRef(dropRef(node))} className={`align-middle ${(item.estado === 1 ? 'text-success' : 'text-danger')}`}>
                <td className='text-center'>
                    <FontAwesomeIcon icon={faArrowsAlt} />
                </td>
                {obtenerInterfaz(cuenta.interfaces, Interfaz) && (
                    <td className='text-center'>
                        <Button variant={(item.estado === 1 ? 'success' : 'danger')} onClick={() => dirigirProducto(item.codigo)}>
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </Button>
                    </td>
                )}
                <td className='text-center'>
                    <img
                        src={item.imagenes.length > 0 ? item.imagenes[0].url['100'] : "https://via.placeholder.com/50"}
                        alt=""
                        height='100'
                        width='100'
                        style={{ transition: "transform 0.2s ease-in-out" }}
                        onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.3)"}
                        onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
                    />
                </td>
                <td className='text-end'>{separarDigitos(item.codigo)}</td>
                <td>{item.marca.nombre}</td>
                <td>{item.modelo}</td>
                <td>{item.nombre}</td>
                <td>{item.ruta}</td>
                <td className='text-end'>{parseInt(item.precio).toLocaleString('es-PY')}</td>
                <td className='text-end'>{parseInt(item.cantidad).toLocaleString('es-PY')}</td>
                <td>{item.deposito}</td>
                <td>{<FontAwesomeIcon icon={(item.estado === 0 ? faEyeSlash : faEye)} />}{(item.estado === 1 ? 'Visible' : 'No visible')}</td>
            </tr>
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12'>
                        <h1>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faCubes} />)} Productos
                        </h1>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <BarraNavegacion paginas={[
                            { direccion: '/principal', nombre: 'Principal' },
                            { direccion: '/productos/1/10/tm/tc', nombre: 'Productos' }
                        ]} />
                    </div>

                    {/* Paginado superior a la derecha */}
                    <div className='col-md-12 d-flex justify-content-end'>
                        <Pagination>
                            <Pagination.Prev onClick={() => dirigir(pagina - 1)} disabled={pagina <= 1} />
                            {paginas.slice(0, 20).map((pag, i) => (
                                <Pagination.Item key={i} active={pag === pagina} onClick={() => dirigir(pag)}>
                                    {pag}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => dirigir(pagina + 1)} disabled={pagina >= totalPaginas} />
                        </Pagination>
                    </div>

                    <div className='col-md-12 mt-2'>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="Buscar por código"
                                type='number'
                                value={codigo}
                                onChange={(e) => setCodigo(e.target.value)}
                                onKeyDown={eventoEnter}
                            />
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="Buscar por nombre"
                                type='text'
                                value={nombre}
                                onChange={(e) => setNombre(e.target.value)}
                                onKeyDown={eventoEnter}
                            />
                            <Button variant='outline-secondary' onClick={buscarCodigo}>Buscar</Button>
                        </InputGroup>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <Button variant='secondary' onClick={mostrarFiltros}>
                            <FontAwesomeIcon icon={faFilter} /> Filtros
                        </Button>
                        <Button variant='primary' onClick={confirmarDistribucion} style={{ marginLeft: '10px' }}>
                            Confirmar Distribución
                        </Button>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <Table striped bordered hover size="lg" className='table-dark'>
                            <thead>
                                <tr>
                                    <th className='text-center'>Ordenar</th>
                                    {obtenerInterfaz(cuenta.interfaces, Interfaz) && <th className='text-center'>Detalles</th>}
                                    <th className='text-center'>Imagen</th>
                                    <th className='text-center'>Código</th>
                                    <th className='text-center'>Marca</th>
                                    <th className='text-center'>Modelo</th>
                                    <th className='text-center'>Nombre</th>
                                    <th className='text-center'>Ruta</th>
                                    <th className='text-center'>Precio</th>
                                    <th className='text-center'>Cantidad</th>
                                    <th className='text-center'>Deposito</th>
                                    <th className='text-center'>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datos.map((item, index) => (
                                    <DraggableRow key={item.codigo} item={item} index={index} />
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <Offcanvas show={filtros} onHide={cerrarFiltros} className='bg-dark text-white'>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Filtros</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <FloatingLabel label="Marca">
                            <Form.Select aria-label="Marca" value={marca} onChange={establecerMarca}>
                                <option value="sm">Productos sin marca</option>
                                <option value="tm">Todas las marcas</option>
                                {
                                    marcas.filter(marca => marca.nombre && marca.nombre.trim() !== '').map((marca, key) => (
                                        <option value={marca.ruta} key={key}>{marca.nombre}</option>
                                    ))
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel label="Categoría" className="mt-3">
                            <Form.Select value={categoria} onChange={establecerCategoria}>
                                {categorias.map((item, index) => (
                                    <option key={index} value={item.codigo}>{item.nombre}</option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel label="Subcategoría" className="mt-3">
                            <Form.Select value={subcategoria} onChange={establecerSubcategoria}>
                                <option value="ss">Subcategorías sin especificar</option>
                                <option value="ts">Todas las subcategorías</option>
                                {
                                    subcategorias.map((item, index) => (
                                        <option key={index} value={item.nombre}>{item.nombre}</option>
                                    ))
                                }
                            </Form.Select>
                        </FloatingLabel>


                        <FloatingLabel label="Intervalo" className="mt-3">
                            <Form.Select value={intervalo} onChange={establecerIntervalo}>
                                <option value={4}>4</option>
                                <option value={6}>6</option>
                                <option value={8}>8</option>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </DndProvider>
    );
}
