import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPager, faImage, faPercent, faWindowRestore, faVideo, faBoxes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Outlet } from 'react-router-dom';
import { ButtonToolbar, ButtonGroup, Card, ToggleButton } from 'react-bootstrap';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { useUsuarioContexto } from '../proveedor/usuario';
import { obtenerPermiso } from '../recursos/funcion';

export default function Publicidad() {
    const cuenta = useUsuarioContexto();
    const Interfaz = 'publicidad';
    const navegar = useNavigate();
    const [ValorRadio, setValorRadio] = useState('0');

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12'>
                        <h1><FontAwesomeIcon icon={faPager} />Publicidad</h1>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/publicidad',
                                nombre: 'Publicidad'
                            }
                        ]} />
                    </div>
                    <div className='col-md-12 mt-2'>
                        <ButtonToolbar className='d-flex gap-2'>
                            {
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'emergentes') ? <ButtonGroup>
                                    <ToggleButton id='radio1' variant='outline-primary' name='radio' type='radio' value={'1'} onChange={(e) => {
                                        navegar('emergentes/1/10');
                                        setValorRadio(e.currentTarget.value);
                                    }} checked={ValorRadio === '1'}>
                                        <FontAwesomeIcon icon={faWindowRestore} /> Emergentes
                                    </ToggleButton>
                                </ButtonGroup> : '')
                            }
                            {
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'promociones') ? <ButtonGroup>
                                    <ToggleButton id='radio2' variant='outline-primary' name='radio' type='radio' value={'2'} onChange={(e) => {
                                        navegar('promociones/1/10');
                                        setValorRadio(e.currentTarget.value);
                                    }} checked={ValorRadio === '2'}>
                                        <FontAwesomeIcon icon={faPercent} /> Promoción
                                    </ToggleButton>
                                </ButtonGroup> : '')
                            }


                            {   
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'promociones') ? <ButtonGroup>
                                    <ToggleButton id='radio8' variant='outline-primary' name='radio' type='radio' value={'8'} onChange={(e) => {
                                        navegar('combos/1/10');
                                        setValorRadio(e.currentTarget.value);
                                    }} checked={ValorRadio === '8'}>
                                        <FontAwesomeIcon icon={faBoxes} /> Combos
                                    </ToggleButton>
                                </ButtonGroup> : '')
                            }
                            {
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'portada') ? <ButtonGroup>
                                    <ToggleButton id='radio3' variant='outline-success' name='radio' type='radio' value={'3'} onChange={(e) => {
                                        navegar('portada');
                                        setValorRadio(e.currentTarget.value);
                                    }} checked={ValorRadio === '3'}>
                                        <FontAwesomeIcon icon={faImage} /> Portada
                                    </ToggleButton>
                                </ButtonGroup> : '')
                            }
                            {
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'portada') ? <ButtonGroup>
                                    <ToggleButton id='radio9' variant='outline-success' name='radio' type='radio' value={'9'} onChange={(e) => {
                                        navegar('portadaLateral');
                                        setValorRadio(e.currentTarget.value);
                                    }} checked={ValorRadio === '9'}>
                                        <FontAwesomeIcon icon={faImage} /> Portada Lateral
                                    </ToggleButton>
                                </ButtonGroup> : '')
                            }
                            {
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'contador') ? <ButtonGroup>
                                    <ToggleButton id='radio4' variant='outline-success' name='radio' type='radio' value={'4'} onChange={(e) => {
                                        navegar('contador');
                                        setValorRadio(e.currentTarget.value);
                                    }} checked={ValorRadio === '4'}>
                                        <FontAwesomeIcon icon={faImage} /> Contador
                                    </ToggleButton>
                                </ButtonGroup> : '')
                            }
                            {
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'sello') ? <ButtonGroup>
                                    <ToggleButton id='radio5' variant='outline-success' name='radio' type='radio' value={'5'} onChange={(e) => {
                                        navegar('sello');
                                        setValorRadio(e.currentTarget.value);
                                    }} checked={ValorRadio === '5'}>
                                        <FontAwesomeIcon icon={faImage} /> Sello
                                    </ToggleButton>
                                </ButtonGroup> : '')
                            }
                            {
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'banner') ? <ButtonGroup>
                                    <ToggleButton id='radio6' variant='outline-success' name='radio' type='radio' value={'6'} onChange={(e) => {
                                        navegar('banner');
                                        setValorRadio(e.currentTarget.value);
                                    }} checked={ValorRadio === '6'}>
                                        <FontAwesomeIcon icon={faImage} /> Banner
                                    </ToggleButton>
                                </ButtonGroup> : '')
                            }

                            {
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'videos') ? <ButtonGroup>
                                    <ToggleButton id='radio7' variant='outline-success' name='radio' type='radio' value={'7'} onChange={(e) => {
                                        navegar('videos');
                                        setValorRadio(e.currentTarget.value);
                                    }} checked={ValorRadio === '7'}>
                                        <FontAwesomeIcon icon={faVideo} /> Video
                                    </ToggleButton>
                                </ButtonGroup> : '')
                            }
                        </ButtonToolbar>
                    </div>
                    <div className='col-md-12 mt-3 mb-3'>
                        <Card>
                            <Outlet />
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}